<template>
  <el-form :model="form" :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in itemList" :key="index" class="inline">
          <el-form-item :label="item.label" :prop="item.prop" >
              <el-select v-if="item.type === 'select'" :filterable="true" v-model="form[item.prop]" placeholder="请选择" clearable style="width:180px;">
                <el-option v-for="i in item.list" :key="i.key" :label="i.value" :value="i.key"/>
              </el-select>
              <el-date-picker 
                  v-if="item.type === 'date'" 
                  :type="item.dateType" 
                  v-model="form[item.prop]"
                  placeholder="请选择" clearable
              />
				<!-- <dateRange
					v-if="item.type === 'dateRange'" 
					:start="form[item.start]"
					:end="form[item.end]"
					:type="item.type"
					@update:start="handleStart"
					@update:end="handleEnd"
				/> -->
			  	<el-date-picker
				  	v-if="item.type === 'dateRange'" 
					value-format="YYYY-MM-DD"
					v-model="form[item.prop]"
					type="daterange"
					unlink-panels
					range-separator="-"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				/>
              <el-input-number v-if="item.type === 'number'" v-model="form[item.prop]" placeholder="请输入" clearable  style="width:180px;"/>
              <el-input v-if="item.type === 'input'" v-model="form[item.prop]" placeholder="请输入" clearable  style="width:180px;"/>
              <el-cascader v-if="item.type === 'cascader'" v-model="form[item.prop]" :options="item.list" :props="item.props" clearable 
              :show-all-levels="false" style="width:180px;" @change="(i) =>casChange(i,item.prop)"  placeholder="请选择"/>
          </el-form-item>
      </div>
      <div class="inline">
        <el-form-item>
            <!-- <el-button @click="handleSearch" type="primary" icon="Search">查询</el-button>
            <el-button @click="handleReset" type="primary" plain icon="Refresh">重置</el-button> -->
            
            <BlockBtn text="查询" :textStyle="{'margin-left':'10px', 'padding':'1px 20px', 'font-size':'14px',}" @click="handleSearch"/>
            <BlockBtn text="重置" :textStyle="{'margin-left':'10px', 'padding':'1px 20px', 'font-size':'14px',}" @click="handleReset"/>
        </el-form-item>
      </div>
  </el-form>
</template>

<script>
    import BlockBtn from './blockBtn.vue'
    import dateRange from './dateRange.vue'
    export default {
      name: 'SearchNormalForm',
      props: ['itemList'],
      data() {
        return {
            dateFormat: 'yyyy-MM-dd',
            dateValueFormat: 'yyyy-MM-dd',
            form: {}
        }
      },
      components:{dateRange,BlockBtn},
      methods: {
        casChange(i,prop) {
          this.form[prop] = []
          i.forEach(res => {
            this.form[prop].push(res.slice(-1)[0])
          });
        },
        handleSearch: function() {
          this.$emit('search', this.form)
        },
        handleReset: function() {
          this.form = {}
          this.$emit('reset')
        },
        handleStart: function(time) {
          this.itemList.map(item => {
            if(item.type === 'dateRange') {
              this.$set(this.form, item.start, time)
            }
          })
        },
        handleEnd: function(time) {
          this.itemList.map(item => {
            if(item.type === 'dateRange') {
              this.$set(this.form, item.end, time)
            }
          })
        },
      }
    }
  
</script>

<style lang="scss" scoped>
.inline {
    display: inline-flex;
}
</style>