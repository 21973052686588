import {postAction, getAction} from './manage';
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}
// 商品列表
export const productList = (params) => {
    return postAction(genV1ApiUrl('shop/product/page-list'), params)
}
// 商品保存
export const productSave = (params) => {
    return postAction(genV1ApiUrl('shop/product/save'), params)
}
// 商品状态
export const productState = (params) => {
    return postAction(genV1ApiUrl('shop/product/state'), params)
}

// 商品详情
export const productView = (params) => {
    return postAction(genV1ApiUrl('shop/product/view'), params)
}
// 商品售卖列表
export const productSell = (params) => {
    return postAction(genV1ApiUrl('product/sell/list'), params)
}


