import api from '@/api/api'
import OSS from 'ali-oss'

/**
 * 创建并下载文件
 * UploadFile 上传文件
 * NewCosClient 阿里云配置
 * getUploadCredential 本地上传凭证
 * fileName 文件名
 * content 文件内容
 * OSSURL 导出oss地址
 */
export async function UploadFile(fileName, content) {
  let key = `upload/${new Date().getTime()}/${fileName}`
  let client = await NewCosClient()
  await client.put(key,content).then(res =>{
  }).catch(err => {
    console.error('-->putObject', err)
    key = null
  })
  return key
}
export async function AvatarFile(fileName, content) {
  let key = `avatar/${new Date().getTime()}/${fileName}`
  let client = await NewCosClient()
  await client.put(key,content).then(res =>{
  }).catch(err => {
    console.error('-->putObject', err)
  })
  return key
}

export const genID = ( length ) => {
  return Number( Math.random().toString().substr( 3, length ) + Date.now() ).toString( 36 )
}
export const OSSURL = process.env.VUE_APP_OSSURL;
const NewCosClient =  () => {
  return new Promise((res,rej)=>{
    getUploadCredential().then(resp =>{
     const data = resp
     let client = new OSS({
       // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
         region: 'oss-cn-beijing',
         // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
         accessKeyId: data.credentials.AccessKeyId,
         accessKeySecret: data.credentials.AccessKeySecret,
         // 填写Bucket名称。关于Bucket名称命名规范的更多信息，请参见Bucket。
         bucket: data.bucket,
         host: data.host,
         stsToken:data.credentials.SecurityToken,
         refreshSTSToken: async () => {
           const refreshToken = await getUploadCredential();
           const{data} = refreshToken.data
           return {
             accessKeyId: data.credentials.AccessKeyId,
             accessKeySecret: data.credentials.AccessKeySecret,
             stsToken:data.credentials.SecurityToken,
           };
         },
       })
      res(client) 
    })
  })

}

export async function UploadMissionFile(fileName, content) {
	let key = `upload/mission/${fileName}`
	let client = await NewCosClient()
	await client.put(key,content).then(res =>{
	}).catch(err => {
	  console.error('-->putObject', err)
	})
	return key
}


// 获取图片上传的凭证
const getUploadCredential = () => {
  return api.UploadCredential()
}

// 通过文件名称判断类型是否符合
export const checkFileTypeByName = (name, type) => {
	const fileType = name.split('.')?.slice(-1)?.[0];
	if(!name || !type) {
		return false;
	}
	if(Array.isArray(type)) {
		return type.includes(fileType);
	}
	if(typeof type === 'string') {
		return fileType === type;
	}
	return false;
}