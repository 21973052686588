<template>
    <div class="lookBtn" :style="props?.textStyle">{{props?.text}}</div>
</template>

<script setup>
import { defineProps } from 'vue'
const props = defineProps(['text','textStyle'])
</script>

<style lang="less" scoped>
.lookBtn {
    padding: 3px 10px;
    border: 1px solid #FCFCFC;
    font-size: 12px;
    cursor: pointer;
    display: inline-block;
    border-radius: 5px;
    &:hover {
        background-color: #46485f;
    }
}

</style>
