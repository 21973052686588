<template>
    <div class="lookBtn" :class="{'disable':!isAgree}":style="props?.textStyle">{{props?.text}}</div>
</template>

<script setup>
import { defineProps, watch, } from 'vue'
const props = defineProps(['text','textStyle','isAgree'])
let isAgree
if(props?.isAgree == undefined) {
    isAgree = true
}else {
    isAgree = props?.isAgree
}
watch(() => props?.isAgree, (val) => {
    isAgree = val
})
</script>

<style lang="less" scoped>
.lookBtn {
    padding: 6px 25px;
    background-color: #383B48;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    border-radius: 7px;
    &:hover {
        background-color: rgba(174,59,52,.17);
        color: #923330;
    }
}
.activeBtn {
    background-color: rgba(174,59,52,.17);
    color: #923330;
}
.disable {
    cursor:not-allowed;
    &:hover {
        background-color: #383B48;
        color: #fdfdfd;
    }
}
</style>
