<template>
  <el-pagination
    @current-change="handleCurrentChange"
    @size-change="handleSizeChange"
    :current-page="currentPage"
    :page-sizes="[1, 10, 12, 20, 50, 100]"
    :page-size="pageSize"
    layout="total, prev, pager, next, sizes"
    :total="totalItems"
    :background="true"
  />
</template>

<script>
export default {
  name: 'Pagination',
  // 通过props来接受父组件的值
  props: {
    // 当前页码
    currentPage: {
      type: Number,
      required: true,
    },
    //每页显示条数
    pageSize: {
      type: Number,
      required: true,
    },
    //总条数
    totalItems: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleCurrentChange(val) {
       /* $emit来触发自定义事件`current-change`，并传递`val`为参数，这时候就会调用父组件中的 			`handleCurrentChange` 方法，因为父组件中有一个`@current-change="handleCurrentChange`方法 */
      this.$emit('current', val);
    },
    handleSizeChange(val) {
        //$emit来触发自定义事件`size-change`，并传递`val`为参数,这时候也会调用父组件中的方法
      this.$emit('size', val);
    },
  },
};
</script>
<style>

</style>
