<template>
    <el-dialog
        :model-value="props.productDialog"
        :show-close="true"
        width="900"
        :before-close="handleClose"
		:close-on-click-modal="false"
          >
        <div class="dialogBox">
            <div class="dialogTitle">
                版本选择
                <!-- <span class="tips">
                  （付款后自动开通使用，支持<span  class="tip">7</span>天内无理由退款） 
                </span> -->
            </div>
            <div class="listBox">
                <span v-for="(i,index) in sellData" :key="index" v-show="i.productType != 'product_free'">
                    <div class="list" :class="{versionActive: versionActive == i.tplId}" @click="versionClick(i)">
                        <div class="top">
                            <img :src="OSSURL + i.img" alt="">
                            <p class="listTitle">{{ i.name }}</p>
                        </div>
                        <el-tooltip
                          v-for="(j,index) in i.tags" :key="index"
                          class="box-item"
                          effect="dark"
                          :content="j"
                          placement="bottom-start"
                        >
                          <div class="li"><span class="openStatus">{{j}}</span></div>
                        </el-tooltip>
                    </div>
                    
                    <!-- <el-popover placement="bottom" :width="200" v-if="i.productType == 'product_ask'" trigger="click">
                        <div class="phoneBox">
                            <div class="label">
                                <el-icon><Phone /></el-icon>
                            </div>
                            <div class="value">
                                0314-2539999
                            </div>
                        </div>
                        <div class="imgBox">
                            <img :src="OSSURL + 'home/sell.png'" alt="">
                        </div>
                        <template #reference>
                            <div class="list" :class="{versionActive: versionActive == i.tplId}">
                                <div class="top">
                                    <img :src="OSSURL + i.img" alt="">
                                    <p class="listTitle">{{ i.name }}</p>
                                </div>
                                <div class="li" v-for="(j,index) in i.tags" :key="index"><span class="openStatus">{{j}}</span></div>
                            </div>
                        </template>
                    </el-popover> -->
                </span>
                <span class="fillSpan"></span>
                <span class="fillSpan"></span>
                <span class="fillSpan"></span>
                <span class="fillSpan"></span>
            </div>
            
            <div class="company"  v-if="productType == 'product_ask'">
              <div class="phoneBox">
                  <div class="label">
                      <el-icon><Phone /></el-icon>
                  </div>
                  <div class="value">
                      0314-2539999
                  </div>
              </div>
              <div class="imgBox">
                  <img :src="OSSURL + 'home/sell.png'" alt="">
              </div>
            </div>
            <div class="dialogTitle" v-if="productType != 'product_ask'">
            商品选择
            </div>
            <div class="productBox"  v-if="productType != 'product_ask'">
                <div class="product" :class="{productActive: productActive == i.productId}" v-for="(i,index) in productData" :key="index" @click="productClick(i)">
                    {{ i.expireName }}
                </div>
            </div>
            <div class="paymentBox"  v-if="productType != 'product_ask'">
                <div class="left">
                    <div class="leftText" v-if="!qrCodeImg">
                        <p class="text">开通前请阅读</p>
                        <p class="accord" @click="routerToPay"><span style="color: var(--red);">《付费购买协议》</span></p>
                        <div class="btn" @click="agreePay">
                        同意并支付
                        </div>
                    </div>
                    <div class="left"  v-else>
                        <img class="qrImg" :src="qrCodeImg" :class="{activing: activing}" alt="">
                        <el-icon class="iconSize" v-if="activing" @click="refreshCode"><RefreshRight /></el-icon>
                    </div>
                </div>
                <div class="right">
                    <div class="top">实付：<span class="money"><span class="icon">￥</span>{{ payAmount }}</span></div>
                    <div class="bottom">
                        <img class="sellImg" src="@/assets/wx.png" alt="">
                        <img class="sellImg" src="@/assets/zfb.png" alt="">
                        <p class="rightText">扫码支付</p>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog
        :model-value="props.payDialog"
        :show-close="true"
        width="900"
        :before-close="handleClose"
		:close-on-click-modal="false"
        >
        <div class="box">
            <img src="@/assets/pay.png" alt="">
            <div class="big">
                恭喜您，已支付成功
            </div>
            <div class="small">
                可以在「头像-购买记录」中查看订单详情
            </div>
            <div class="btn">
                <div class="grey" @click="routeToHome">进入工作台</div>
                <div class="red" @click="routeToDesign">开始设计</div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { OSSURL} from '@/util/file';
import { defineProps,defineEmits, watch } from 'vue'
import { ref, computed, unref } from 'vue'
import Constant from '@/constant/constant';
import { 
	orderSelect,
	qrCode,
	orderCreate,
} from '@/api/order.js';
const props = defineProps(['payDialog','productDialog','sellData','selectItem'])
const emits = defineEmits(['payClose','productClose']);
import {useRouter} from 'vue-router';
const router = useRouter()
let timer
const activing = ref(false)
const versionActive = ref()
const productActive = ref()
const productData = ref()
const payAmount = ref()
const sellData = computed(() => props.sellData)
const selectItem = computed(() => props.selectItem || props.sellData[0])
const qrCodeImg = ref()
const productType = ref()
const versionClick = (i) => {
  if(versionActive.value == i.tplId) {
    return
  }
  clearInterval(timer)
  qrCodeImg.value = null
  versionActive.value = i.tplId
  productData.value = i.product
  productType.value = i.productType
  if(i.productType != 'product_ask') {
    productActive.value = productData.value[0].productId
    payAmount.value = productData.value[0].payAmount
  }
}

const handleClose = () => {
    qrCodeImg.value = null
    activing.value = false
    emits('payClose',false)
    emits('productClose',false)
	clearInterval(timer)
}
const routeToHome = () => {
    qrCodeImg.value = null
    activing.value = false
    emits('payClose',false)
    emits('productClose',false)
    router.push({ path: '/p'}).then(() => {
        window.location.reload();
    })
}
const routeToDesign = () => {
    qrCodeImg.value = null
    emits('payClose',false)
    emits('productClose',false)
    window.open(process.env.VUE_APP_DESIGN_URL)
}
const productClick = (i) => {
	productActive.value = i.productId
	payAmount.value = i.payAmount
}
const refreshCode = () => {
	clearInterval(timer)
	activing.value = false
	agreePay()
}
const routerToPay = () => {
	window.open('/s/agree-pay', '_blank');
}
const agreePay = () => {
	let param = {
	orderFrom:  Constant.statePc,
	product:[{
		productId:productActive.value,
		productNum:1
	}]
	}
	orderCreate(param).then(res => {

	qrCode({url:process.env.VUE_APP_MOBILE_HOME_PATH + '/qrCode?order=' + res.orderNo}).then(i => {
		qrCodeImg.value = i.img
		timer = setInterval(() => {
            orderSelect({orderNo:res.orderNo}).then(j => {
                if(j.orderState != Constant.payOrderStateWait) {
                activing.value = true
                if(j.orderState == Constant.payOrderStateSucces || j.orderState != Constant.payOrderStateIng) {
                    if(j.orderState == Constant.payOrderStateSucces) {
                        emits('payClose',true)
                        emits('productClose',false)
                    }
                    clearInterval(timer)
                }
                }
            })
		}, 2000);
	})
	})
}
watch(() => selectItem.value, (val) => {
    if(val) {
      productType.value = val.productType
      versionActive.value = val.tplId
      productData.value = val.product || []
      productActive.value = productData.value[0]?.productId
      payAmount.value = productData.value[0]?.payAmount
    }
})
</script>

<style lang="less" scoped>
.company {
  .phoneBox {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    .label {
      display: inline-block;
    }
    .value {
      display: inline-block;
      padding-left: 10px;
    }
  }
  .imgBox {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    img {
      width: 180px;
    }
  }
}
:deep(.el-dialog) {
  border-radius: 20px;
  padding: 24px 30px 40px;
}
.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fdfdfd;
    img {
        width: 166px;
        height: 166px;
    }
    .big {
        font-size: 33px;
        margin-top: 40px;
    }
    .small {
        font-size: 22px;
        margin-top: 25px;
    }
    .btn {
        font-size: 23px;
        overflow: hidden;
        margin-top: 55px;
        .grey {
            float: left;
            padding: 5px 20px;
            background-color: #161824;
            border-radius: 10px;
            cursor: pointer;
        }
        .red {
            float: left;
            padding: 5px 20px;
            background-color: #AE3B34;
            border-radius: 10px;
            margin-left: 80px;
            cursor: pointer;
        }
    }
}

.buyDialog {
	.tabsBox {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		.tab {
			font-size: 26px;
			color: #AE3B34;
		}
	}
}
.dialogBox {
  color: #fdfdfd;
  font-size: 16px;
  .paymentBox {
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    padding: 25px 20px;
    border: 1px solid #fdfdfd;
    margin-top: 30px;
    border-radius: 12px;
    display: flex;
    .qrImg {
      width: 108px;
      height: 108px;
    }
    .activing {
      filter: blur(5px);
    }
    .iconSize {
      font-size: 70px;
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      cursor: pointer;
    }
    .right {
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .top {
        font-size: 20px;
        margin-top: 10px;
        .money {
          color: #AE3B34;
          font-size: 42px;
          .icon {
            font-size: 29px;
          }
        }
      }
      .bottom {
        .sellImg {
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-left: 5px;
          position: relative;
          top: 2px;
        }
        .rightText {
          display: inline-block;
          font-size: 15px;
          margin-left: 7px;
        }
      }
    }
    .left {
      width: 108px;
      height: 100%;
      background-color: #FBFAFA;
      border-radius: 8px;
      text-align: center;
      position: relative;
      .leftText {
        color: #020202;
        font-size: 10px;
        overflow: hidden;
        .text {
          margin-top: 23px;
        }
        .accord {
          cursor: pointer;
        }
        .btn {
          margin: 0 6px;
          margin-top: 10px;
          background-color: #AE3B34;
          border-radius: 5px;
          height: 18px;
          line-height: 18px;
          font-size: 11px;
          color: #FDFDFD;
          cursor: pointer;
        }
      }
    }
  }
  .productBox {
    display: flex;
    .product {
      width: 100px;
      height:50px;
      box-sizing: border-box;
      border: 1px solid #fdfdfd;
      border-radius: 12px;
      margin-top: 15px;
      margin-right: 30px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
    .productActive {
      border-color: #AE3B34;
    }
  }
  .listBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    .top {
      font-size: 17px;
      .listTitle {
        display: inline-block;
        overflow:hidden;
        text-overflow:ellipsis;//文本溢出显示省略号
        white-space:nowrap;//文本不会换行
        width: calc(100% - 25px);
        margin-left: 5px;
      }
      img {
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        bottom: 1px;
      }
    }
    .li {
      float: left;
      width: calc(100%);
      overflow:hidden;
      text-overflow:ellipsis;//文本溢出显示省略号
      white-space:nowrap;//文本不会换行
      position: relative;
      padding-left: 15px;
      margin-left: 6px;
      box-sizing: border-box;
      margin-top: 5px;
      font-size: 12px;
      .openStatus::before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 8px;
          left: 0px;
          border-radius: 50%;
          background-color: #888991;
      }
    }
    .fillSpan {
        width: 192px;
        height: 0;
    }
    .list {
      width: 192px;
      height: 180px;
      box-sizing: border-box;
      border: 1px solid #FCFCFC;
      border-radius: 12px;
      margin-bottom: 15px;
      padding: 20px;
      cursor: pointer;
    }
    .versionActive {
      border-color: #AE3B34;
    }
  }
  .dialogTitle {
    font-size: 20px;
    .tips {
      font-size: 16px;
      margin-left: 10px;
      .tip {
        font-size: 24px;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #f4cc6b, #d8a141);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        display: inline-block; /* for Firefox */
        margin: 0 4px;
        position: relative;
        top: 2px;
      
      }
    }
  }
}
</style>