<template>
    <div style="color: #fdfdfd;">
        <div class="box" v-if="payType == '9003'">
            <div class="text">
                请使用微信或者支付宝扫码
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { 
    qrCode,
    orderPay,
} from '@/api/order.js';
import Constant from '@/constant/constant';
import {useRoute} from 'vue-router'
const route = useRoute()
const order = ref(route.query.order)
const payType = ref('9002')
const userAgent = ref(window.navigator.userAgent.toLowerCase())
const pay = () => {
    orderPay({orderNo: order.value, payType: payType.value}).then(res => {
        window.location.href = res.payLink;
    })
}
if (userAgent.value.match(/Alipay/i) == "alipay") {  
    //alert("支付宝浏览器");  
    payType.value = Constant.stateZfb
    pay()

} else if (userAgent.value.match(/MicroMessenger/i) == "micromessenger") {   
    // alert("微信浏览器");   
    payType.value = Constant.stateVx
    pay()
} else {
    payType.value = '9003'
}
</script>

<style lang="scss" scoped>
.box {
    overflow: hidden;
    .text {
        text-align: center;
        font-size: 20px;
        margin-top: 100px;
    }
}
</style>
  