/**
 * 统一定义常量对象
 */
// import store from '@/store'
const Constant = {
    // http请求超时
    HttpTimeout: 10000,
    // http header中的token，存储在storage中的名字
    HttpAccessToken: 'accessToken',
    // http常用状态码
    HttpCode: {
        Code200: 200,
        Code400: 400,
        Code401: 401,
        Code403: 403,
        Code404: 404,
        Code408: 408,
        Code500: 500,
        Code501: 501,
        Code502: 502,
        Code503: 503,
        Code504: 504,
        Code505: 505,
    },
    // 扫码判断
    stateVx: '9001', // 微信
    stateZfb: '9002', // 支付宝
    // 扫码判断
    statePc: '9001', // pc
    stateH5: '9002', // h5
    // 订单状态
    payOrderStateWait: '9001', // 待处理
    payOrderStateIng: '9002', // 处理中
    payOrderStateSucces: '9003', // 处理成功
    payOrderStateFail: '9004', // 处理失败
    payOrderStateCancel: '9005', // 已取消


    // 权限码列表，存储在storage中的名字
    PermCode: 'codes', // 权限码
    PermUserStatus: 'userStatus', // 用户当前状态 1正常 2过期 3禁用
    PermUserIdentity: 'userIdentity', // 用户当前身份 1个人 2院校 3企业 4平台

    // 分页数据默认
    PaginationDefaultPage: 1, // 当前页
    PaginationDefaultSize: 20,// 每页显示数量

    // 微信绑定状态
    wechatBind: 1, // 已绑定
    wechatNotBind: 0,// 未绑定

    // 默认分组
    SchemeTrue: 1, // 默认
    SchemeFalse: 0,// 非默认


    // 视图分组
    RenderTypeNormal: 1, // 普通
    RenderTypeWhole: 2,// 全景
    RenderTypeDown: 3,// 俯视

    // loading内容定制
    LoadingMessage: "拼命加载中",
    // 数据状态
    StateEnable: 1, // 正常
    StateDisable: 2, // 禁用
    StateDelete: 127, // 删除

    DictCodeCompanyFrom: 'company_from',// 商家来源
    DictCodeCompanyType: 'company_type',// 商家类型
    DictCodeUserType: 'user_type',// 账户类型
    DictCodeUserGroup: 'user_group',// 账户分组 
    DictCodeModelPosition: 'model_position',// 模型摆放位置
    DictCodeTagType: 'tag_type', // 标签类型
	DictCodeMissionType: 'mission_type',
	DictCodeRenderType: 'render_type',//渲染权益
	DictCodeRenderXk: 'render_xk',//渲染清晰度
	DictCodeRenderProportion: 'render_proportion',//构图比例
	DictCodeRenderTimeUnit: 'render_time_unit',//渲染时段单位
	DictCodeBfCode: 'bf_code',//权益分类
	DictCodeBfTplLevel: 'bf_tpl_level',//模版等级
	DictCodeBfRender: 'bf_render',//模版等级
	DictCodeShopProductExpire: 'shop_product_expire',//商品有效期
	DictCodeShopProductType: 'shop_product_type',//商品类型
	DictCodePayOrderType: 'pay_order_type',//订单类型
	DictCodePayOrderState: 'pay_order_state',//订单状态
	DictCodeProjectTplType: 'project_tpl_type',//方案模板类型
    


    TreeAuth: 'auth_tree', // 权限树
    TreeArcticle: "article_tree", // 文章分类
    TreeTexture: "texture_tree", // 材质分类
	TreeModel: "model_tree",   // 模型分类
    TreeModelFeature: "model_feature_tree", // 模型特性分类

    // 用户类型
    UserType(id) {
        if(id === this.UserTypePerson) {
            return '个人'
        }else if(id === this.UserTypeSchool) {
            return '院校'
        }else if(id === this.UserTypeCompany) {
            return '企业'
        }else if(id === this.UserTypePlatform) {
            return '平台'
        }
    },
    // 素材tabs
    UserTypeTabs(id) {
        if(id === this.UserTypePerson) {
            return [{
                label:'我的',
                name:this.MaterialPerson,
            },]
        }else if(id === this.UserTypeSchool) {
            return [{
                label:'院校',
                name:this.MaterialSchool,
            },{
                label:'我的',
                name:this.MaterialPerson,
            },]
        }else if(id === this.UserTypeCompany) {
            return [{
                label:'企业',
                name:this.MaterialCompany,
            },{
                label:'我的',
                name:this.MaterialPerson,
            },]
        }else if(id === this.UserTypePlatform) {
            return [{
                label:'公共',
                name:this.MaterialPublic,
            },{
                label:'我的',
                name:this.MaterialPerson,
            },]
        }
    },
    
    
    UserTypePerson: 1, // 个人
    UserTypeSchool: 2, // 院校
    UserTypeCompany: 3, // 企业
    UserTypePlatform: 4, // 平台
    //素材类型
    MaterialPerson: 1, // 个人
    MaterialSchool: 2, // 院校
    MaterialCompany: 3, // 企业
    MaterialPublic: 4, // 公共

    // 当前用户状态
	UserStatusNormal: 1, // 正常
	UserStatusExpired: 2, // 过期
	UserStatusForbidden: 3, // 禁用
    UserStatusFree: 4, // 未付费会员

    // 权限码
    // 方案
    PermProject: '101', // 方案
    PermProjectSelf: '101101', // 我的方案
    PermProjectView: '101102', // 方案详情
    // 素材
    PermMaterial: '102', // 素材
    PermMaterialModel: '102101',// 模型
    PermMaterialModelUpload: '102101101',// 模型上传
    PermMaterialTexture: '102102',// 材质
    PermMaterialTextureUpload: '102102101',// 材质上传
    // 开放素材
    FreeMaterial: '109', // 开放素材
    FreeMaterialBtn: '109101', // 素材审核
    // 商家中心
    PermV2ComCenter: '110', // 商家中心
    PermV2ComCenterSubAccount : '110101', // 子账户
    // 平台配置
    PermV2Plat: '201', // 平台配置
    // 系统设置
    PermV2PlatSys: '201101', // 系统设置
    PermV2PlatSysDict: '201101101', // 数据字典
    PermV2PlatSysTag: '201101102', // 资源标签
    PermV2PlatSysImageCate: '201101103', // 公共材质分类
    PermV2PlatSysModelCate: '201101104', // 公共模型分类
    PermV2PlatSysModelFeature: '201101105', // 模型特性分类
    // 账户权限
    PermV2PlatAccount: '201102', // 账户权限
    PermV2PlatAccountCom: '201102101', // 企业管理
    PermV2PlatAccountMag: '201102102', // 账号管理
    PermV2PlatAccountRole: '201102103', // 平台权限
    // 设计工具
    PermV2PlatTool: '201103', // 设计工具
    PermV2PlatToolQuality: '201103101', // 渲染清晰度
    PermV2PlatToolBase: '201103102', // 基础权益
    PermV2PlatToolTpl: '201103103', // 权益模板
    PermV2PlatToolProjectTpl: '201103104', // 方案模板
    // 商品中心
    PermV2PlatShop: '201104', // 商品中心
    PermV2PlatShopMag: '201104101', // 商品管理
    PermV2PlatShopOrder: '201104102', // 订单管理
    // 消息中心
    PermV2PlatMsg: '201105', // 消息中心
    PermV2PlatMsgNotice: '201105101', // 系统公告
    // 资源中心
    PermV2PlatResource: '201106', // 资源中心
    PermV2PlatResourceArticle: '201106102', // 文档管理
    PermV2PlatResourceCate: '201106101', // 文档分类
    // 任务中心
    PermV2PlatMis: '201107', // 任务中心
    PermV2PlatMisPre: '201107101', // 预缴金额
    PermV2PlatMisCredit: '201107102', // 任务审核
    // 测试权限
    PermV2Test: '999', // 测试权限



    // 菜单列表
    MenuList() {
        return [
            {
                code: this.PermHomePage,
                icon: 'Briefcase',
            },
            {
                code: this.PermV2PlatSys,
                icon: 'Tools',
            },
            {
                code: this.PermV2PlatAccount,
                icon: 'Key',
            },
            {
                code: this.PermV2PlatTool,
                icon: 'Tickets',
            },
            {
                code: this.PermV2PlatShop,
                icon: 'Briefcase',
            },
            {
                code: this.PermV2PlatMsg,
                icon: 'TrendCharts',
            },
            {
                code: this.PermV2PlatResource,
                icon: 'Open',
            },
			{
                code: this.PermV2PlatMis,
                icon: 'Tickets',
            },
        ]
    },
	defaultTagForm() {
		return {
			mj: {
				title: '面积',
				list: [],
				id: 2,
				checked: new Set([])
			},
			fg: {
				title: '风格',
				list: [],
				id: 1,
				checked: new Set([])
			},
			lx: {
				title: '类型',
				list: [],
				id: 3,
				checked: new Set([])
			},
			cj: {
				title: '场景',
				list: [],
				id: 4,
				checked: new Set([])
			},
		}
	},
	imageFileTypes: ['jpg', 'jpeg', 'png', 'svg'],
	requestWhiteList: ['/user/view', '/user/perm', '/bf/user'],
}

export default Constant