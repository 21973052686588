<template>
	<div class="home-tempate">
		<HomeHeader/>
		<LoginComp/>
		<div style="marginTop:73px;">
			<router-view></router-view>	
		</div>
		<HomeFooter/>
	</div>
	
</template>
	
<script setup>
import { ref,computed, onMounted } from 'vue'
import HomeFooter from './homeFooter.vue';
import LoginComp from '@/pages/login/login_form/loginComp.vue';
import HomeHeader from './homeHeader.vue';
import {useStore} from "vuex"

const store = useStore()

onMounted(async() => {
	
	await store.dispatch('getWeChatSetting');
})

</script>
	
<style lang="scss" scoped>
*ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
*p {
	padding: 0;
	margin: 0;
}
.home-tempate {
	width: 100%;
	height: 100%;
	background: #333;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
}
.home-header {
	display: flex;
	padding: 20px 20px;
	align-items: center;
	z-index: 9;
	width: 100%;
	background: rgba(29, 33, 41, 0.6);
	
	.nav {
		margin: 0;
		display: flex;
		align-items: center;
		margin-left: 70px;
		>li {
			color: #fff;
			list-style: none;
			margin-right: 30px;
			cursor: pointer;
		}
	}
	.login-area {
		margin-left: auto;
		margin-right: 40px;
		color: #fff;
		cursor: pointer;
	}
}
.home-footer {
	color: #fff;
	background: #313438;
	padding-top: 48px;
	padding-bottom: 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.footer-info {
		display: flex;
		border-bottom: 1px solid hsla(0, 0%, 100%, .1);
		margin: 0 auto;
		.footer-left {
			width: 260px;
			border-right: 1px solid hsla(0, 0%, 100%, .1);
			margin-bottom: 35px;
			.consult {
				margin-bottom: 42px;
				>p {
					font-size: 14px;
					margin-bottom: 8px;
					line-height: 20px;
					margin-top: 0;
				
				}
				>div {
					display: flex;
    				align-items: center;
					font-size: 20px;
    				line-height: 28px;
					margin-bottom: 8px;
				}
				>span {
					color: hsla(0, 0%, 100%, .5);
					font-size: 14px;
				}
			}
			.cooperation {
				>p {
					font-size: 14px;
					margin-bottom: 12px;
					color: #fff;
				}
				>ul {
					list-style: none;
					padding: 0;
					li {
						display: inline-block;
						white-space: nowrap;
						color: hsla(0, 0%, 100%, .5);
						width: 50%;
						font-size: 14px;
						margin-bottom: 8px;
					}
				}
			}
		}
		.footer-right {
			display: flex;
			font-size: 14px;
			ul {
				margin-left: 82px;
				li {
					&:nth-child(1) {
						color: #fff;
						margin-bottom: 12px;
					}
					color: hsla(0, 0%, 100%, .5);
					margin-bottom: 8px;
				}
			}
		}
	}
	.footer-license {
		>p{
			color: hsla(0, 0%, 100%, .5);
			font-size: 14px;
			text-align: center;
			margin-top: 10px;
		}
	}
}
.content {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
    display: flex;
    align-items: center;
	justify-content: center;
    z-index: 9;
	background: rgba($color: #000000, $alpha: 0.5);
    .form {
		box-sizing: border-box;
		width: 400px;
		background-color: #fff;
		border-radius: 8px;
		padding: 40px 30px;
    }
	.switchover {
    display: flex;
    justify-content: space-between;
    color: #2148f6;
    margin-top: 20px;
    span {
      cursor: pointer;
    }
    .icon {
      width: 13px;
      height: 11px;
    }
  }
}
.custom-button {
	background-color: #cf3861;
	border: #cf3861;
}
</style>