import {postAction, getAction} from './manage';
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}
// 支付订单
export const orderPay = (params) => {
    return postAction(genV1ApiUrl('order/pay'), params)
}
// 订单查询
export const orderSelect = (params) => {
    return postAction(genV1ApiUrl('order/select'), params)
}
// 生成二维码
export const qrCode = (params) => {
    return postAction(genV1ApiUrl('qr/code'), params)
}
// 创建支付订单
export const orderCreate = (params) => {
    return postAction(genV1ApiUrl('order/create'), params)
}
// 购买列表
export const orderBuyList = (params) => {
    return postAction(genV1ApiUrl('order/buy-list'), params)
}
// 订单列表
export const orderPageList = (params) => {
    return postAction(genV1ApiUrl('order/page-list'), params)
}
// 退款接口
export const orderRefund= (params) => {
    return postAction(genV1ApiUrl('order/refund'), params)
}














