<template>
  <div class="right_content">
    <div class="topBox">
      <div class="img">
        <img :src="OSSURL + formList.image" alt="">
      </div>
      <div class="container">
        <div class="sence">
          <span class="spanName">{{formList.name}}</span>
          <div class="edit" @click="editBtn">
            <el-icon :size="20">
              <Edit />
            </el-icon>
            <span>编辑基本信息</span>
          </div>
        </div>
        <div class="user">
          <span>设计师: {{formList.nickName}}</span>
          <span>电话: {{formList.phone}}</span>
          <span>创建时间: {{formList.createTime}}</span>
        </div>
        <div class="editBtn">
          <!-- <el-button type="primary" size="large" @click="routerToDesign">修改设计</el-button> -->
          <BlockBtn :text="'修改设计'" :textStyle="{}" @click="routerToDesign"/>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="contentLeft">
        <div class="item" :class="active===item.id?'active':''" v-for="(item,index) in imgList" :key="index" @click="leftClick(item.id)">
          {{item.name}}
        </div>
      </div>
      <div class="classHeight">
        <div class="contentRight">
          <div class="item" v-for="(item,index) in rightImgList" :key="index">
            <div class="img">
              <img :src="OSSURL + item.image" alt="" v-if="item.isFinish===1 && item.hasError===0">
            </div>
            <div class="textBox" v-if="item.isFinish===0">
                {{item.stateName}}
            </div>
            <div class="textBox" v-if="item.isFinish===1 && item.hasError===1">
                {{item.reason}}
            </div>
            <div class="btnBox"  v-if="item.isFinish===1 && item.hasError===0">
                <el-button type="primary" size="small"  @click="download(item)">下载</el-button>
                <!-- <el-button type="danger" size="small"   @click="deleteBtn(i.id)">删除</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <el-dialog
      v-model="dialogVisible"
      title="编辑信息"
      width="500"
      :close-on-click-modal="false"
     >
        <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name"  placeholder="请输入"/>
            </el-form-item>
            <el-form-item label="方案文件夹" prop="folderId">
                <el-select v-model="form.folderId"  style="width:100%;">
                    <el-option v-for="i in folderList" :key="i.id" :label="i.name" :value="i.id"/>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <BlockBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="dialogVisible=false"/>
                <BlockBtn :text="'提交'" :textStyle="{}" @click="onSubmit(ruleFormRef)"/>
                <!-- <el-button @click="dialogVisible=false">取消</el-button>
                <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >提交</el-button
                > -->
            </span>
        </template>
    </el-dialog>
  </div>
</template>

<script setup>
import BlockBtn from '@/components/common/blockBtn';
import api from '@/api/api'
import {useRoute} from 'vue-router'
import { ref,reactive } from 'vue'
import { OSSURL} from '../../util/file'
import Constant from '@/constant/constant';
import { useStore } from 'vuex';
import messageBox from '@/constant/message';
import PersonPerm from '@/constant/personPerm';
    const vue = useStore()
    const route = useRoute()
    const userStatus = PersonPerm.design()
    const toDesign = PersonPerm.toDesign()
    const userId = vue.state.user.userView.id
    const active = ref(0)
    const form = ref({})
    const formList = ref({})
    const dialogVisible = ref(false)
    const ruleFormRef = ref()
    const imgList = ref([
      {name:'全部', id:0},
      {name:'普通图', id:Constant.RenderTypeNormal},
      {name:'全景图', id:Constant.RenderTypeWhole},
      {name:'俯视图', id:Constant.RenderTypeDown},
    ])
    const rightImgList = ref([])
    const folderList = ref([])
    
    const rules = reactive({
        folderId: [{ required: true, message: '分类不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
    })
    const leftClick = (i) => {
      active.value = i
      getImage(i)
    }
    const editBtn = () => {
      dialogVisible.value = true
        api.ProjectView({id:Number(route.query.id)}).then((resp) => {
        form.value = resp
      }).catch((err) => {
      });
    }
    const getProject = async () =>{
         api.ProjectDetail({id:Number(route.query.id)}).then((resp) => {
          formList.value = resp
        }).catch((err) => {
        });
    }
    const getImage = async (i) =>{
      rightImgList.value = []
      api.ProjectRenderImage({id:Number(route.query.id)}).then((resp) => {
        if(i === 0) {
          rightImgList.value = resp.result
        }
        resp.result.forEach(res => {
          if(res.renderType === i) {
            rightImgList.value.push(res)
          }
        })
      }).catch((err) => {
      });
    }
    
    const TagFaList = async () =>{
         api.FolderList().then((resp) => {
            folderList.value = resp?.result || []
        }).catch((err) => {
        });
    }
    TagFaList()
    getProject()
    getImage(0)
    const routerToDesign = () => {
      if(userStatus) {
        window.open(process.env.VUE_APP_DESIGN_URL+`?id=${Number(route.query.id)}&user_id=${userId}`)
      }else {
        messageBox.error(toDesign)
      }
    };
    //确定提交
    const onSubmit = (formEl) => {
        // return
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
              api.ProjectUpdate(form.value).then((resp) => {
                dialogVisible.value = false
                getProject()
              }).catch((err) => {
              });
            } else {
                return false
            }
        })
    }
    const download = (item) => {
      window.open(OSSURL + item.image)
    }
</script>
<style lang="scss" scoped>
.right_content {    
  color: #fdfdfd;
  position: absolute;
  left: 230px;
  width: calc(100% - 270px);
  height: calc(100% - 148px);
  top: 68px;
  padding: 20px;
  padding-left: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .content {
    width: 100%;
    position: relative;
    background: #161824;
    box-shadow: 5px 6px 11px 5px rgba(14, 14, 14, 0.8);
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    margin-bottom: 45px;
    .classHeight {
      min-height: 536px;
    }
    .contentRight {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .item {
        cursor: pointer;
        width: 280px;
        height: 210px;
        border-radius: 4px;
        margin-left: 29px;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .btnBox {
            position: absolute;
            bottom: 10px;
            right: 0;
            padding: 10px 10px 0 0;
            font-size: var(--smallFont);
            flex-direction: row;
            color: #fff;
            display: none;
        }
        .textBox {
          position: absolute;
          text-align: center;
          // padding: 10px 10px 0 0;
          font-size: 18px;
          flex-direction: row;
          color: red;
        }
        .img {
          width: 100%;
          height: 100%;
          background: rgba(137, 207, 240,);
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          .btnBox {
              display: flex;
          }
        }
      }
    }
    .contentLeft {
      width: 21.21%;
      min-width: 260px;
      flex-shrink: 0;
      border-right: 1px solid rgba(13,13,13,.06);
      padding: 20px 30px 0 20px;
      box-sizing: border-box;
      font-size: 16px;
      opacity: .9;
      color: #fdfdfd;
      font-weight: 540;
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;
        align-items: center;
        height: 48px;
        border-radius: 4px;
        cursor: pointer
      }
      .active {
        background-color: var(--red);
        color: #fff;
      }
    }
  }
  .topBox {
    width: 100%;
    height: 280px;
    background: #161824;
    box-shadow: 5px 6px 11px 5px rgba(14, 14, 14, 0.8);
    border-radius: 4px;
    display: flex;
    .img {
      img {
        width: 330px;
        height: 240px;
        margin: 20px 38px 20px 20px;
      }
    }
    .container {
      .editBtn {
        margin-top: 20px;
      }
      .sence {
        margin-top: 20px;
        margin-bottom: 14px;
        display: flex;
        .spanName {
          font-size: 24px;
          font-weight: 500;
          margin-right: 15px;
        }
        .edit {
          display: flex;
          align-items: center;
          color: var(--red);
          cursor: pointer;
        }
      }
      .user {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 500;
        color: #fdfdfd;
        opacity: .7;
        span {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
