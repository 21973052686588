<template>
  <div class="mune">
    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      class="demo-ruleForm"
      status-icon
    >
    <div class="itemBox">
      <el-form-item prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号" />
          <template #prepend>
			+86
          </template>
      </el-form-item>
    </div>
    <div class="itemBox">
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="请输入验证码" @keyup.enter="submitForm(ruleFormRef)">
          <template #append>
            <span class="verBtn" @click="showVcode" v-show="verification_time == 60">
              发送验证码
            </span>
			      <span class="verBtn" v-show="!(verification_time == 60)">{{ verification_time }}秒后再次发送</span>
          </template>
        </el-input>
      </el-form-item>
    </div>
    <div class="itemBox topStyle">
      <el-form-item>
        <el-button style="width:100%;" type="danger" @click="submitForm(ruleFormRef)" :disabled="!isAgree || clickNum"
          >登录</el-button>
      </el-form-item>
    </div>
	<el-checkbox v-model="isAgree"  size="large" >
		<el-link :underline="false">
			我已阅读并同意
			<span style="color:var(--red)" @click="handleAgree">《用户协议》</span>
			和
			<span style="color:var(--red)" @click="handlePrivacy">《隐私政策》</span>
		</el-link>
	</el-checkbox>

    </el-form>
		<Vcode :show="isShow" @success="onSuccess" @close="onClose" :imgs="imgLsit" />
  </div>
</template>

<script setup>
import Vcode from "vue3-puzzle-vcode";
import { reactive,ref,defineEmits } from 'vue'
import { OSSURL} from '@/util/file'
import messageBox from '@/constant/message'
import api from '@/api/api'
import {Encrypt} from '@/constant/crypto'
import {useStore} from "vuex"
import { useRouter, useRoute } from 'vue-router';
import {jumpToFromPath} from '@/util/common.js';


const emits = defineEmits(['ForgetPassPage']);
const form = ref({
  phone:'',
  code:'',
})
const clickNum = ref(false);
const isAgree = ref(false);
const store = useStore()
const sendForm = ref({})
const ruleFormRef = ref()
const isShow = ref(false);
const rules = reactive({
    phone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
    code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
})
const imgLsit = ref([
  OSSURL+'home/captcha/captcha1.jpg',
  OSSURL+'home/captcha/captcha2.jpg',
  OSSURL+'home/captcha/captcha3.jpg',
  OSSURL+'home/captcha/captcha4.jpg',
  OSSURL+'home/captcha/captcha5.jpg',
])
const verification_time = ref(60)
const sendVerification = () => {
  verification_time.value--
  let timer = setInterval(() => {
    verification_time.value--
    if (verification_time.value <= 0) {
      clearInterval(timer)
      verification_time.value = 60
    }
  }, 1000)
}
const onClose = () => {
  isShow.value = false;
};
const onSuccess = () => {
  api.SendSms(sendForm.value).then(res => {
  }).catch(err => {
    
  })
  sendVerification()
  onClose(); // 验证成功，手动关闭模态框
};
const handleAgree = () => {
	window.open('/s/agreement', '_blank');
}
const handlePrivacy = () => {
	window.open('/s/privacy', '_blank');
}
const showVcode = () => {
  if(form.value.phone==='') {
    messageBox.error("账号不能为空")
  }else {
    api.CaptchaToken({phone:form.value.phone}).then(res => {
      sendForm.value = {
        captchaToken:Encrypt(res.token),
        key:res.key,
        phone:form.value.phone,
      }
      isShow.value = true;
    }).catch(err => {
      
    })
  }
}
const changeResiter = () => {
  emits('ForgetPassPage')
}
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      clickNum.value = true
      store.dispatch('Login',{...form.value,device:1}).then(res => {
        clickNum.value = false
        const path = window.sessionStorage.getItem('loginToPath');
        jumpToFromPath(path);
      })
    } else {
      clickNum.value = false
      console.log('error submit!', fields)
    }
  })
}
</script>

<style lang="less" scoped>
.el-link:hover {
  color: #606266;
  
}
.itemBox {

}
.demo-ruleForm {
  margin-top: 30px;
  height: 100%;
}
.passBtn {
  text-align: right;
  padding: 10px 0;
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}
.verBtn {
  font-size: 12px;
  color: var(--red);
  cursor: pointer;

}
.topStyle {
	margin-top: 30px;
}
</style>