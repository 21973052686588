<template>
	<div class="design-page">
		<img v-for="image in excellentDesignDataList" :key="image" :src="image" alt="Design Image" @click="handleClickImage(image)">
	</div>
	<div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
		<img :src="currImage.replace(/\?.*/, '')" >
	</div>
</template>
	
<script setup>
import {onMounted, ref, computed} from 'vue';
import {excellentDesignDataList} from '@/constant/oss.js';
const currImage = ref('');
const isZoom = ref(false);


const handleClickImage = (image) => {
	isZoom.value = true;
	currImage.value = image;
}

</script>
	
<style lang="scss" scoped>
.design-page {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 24px;
	img {
		width: 295px;
		height: 221px;
		margin: 12px 0;
		transition: transform 1s ease;
		cursor: pointer;
		&:hover {
			transform: scale(1.1);
		}
	}
}
.zoom-img {
	width:100%;
	height: 100%;
	position:fixed;
	top: 0;
	background: rgba(0,0,0,.6);
	img {
		width: 60%;
		max-height: 70%;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		margin: auto;
	}
}
</style>