import {postAction, getAction} from './manage'
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}

// 获取通知列表数据
export const getTableDataApi = (params) => {
    return postAction(genV1ApiUrl('msg/page-list'), params);
}
// 接收人
export const getReceiverApi = () => {
	const params = {page: 1, size: 1000};
    return postAction(genV1ApiUrl('user/list'), params);
}

// 消息保存
export const saveNotificationApi = (params) => {
    return postAction(genV1ApiUrl('msg/save'), params);
}
// 禁用 ｜ 删除 ｜ 启用
export const updateNotiStateApi = (params) => {
    return postAction(genV1ApiUrl('msg/state'), params);
}
// 全部消息通知
export const getNotiListApi = (params) => {
    return postAction(genV1ApiUrl('msg/inbox/list'), params);
}
// 消息概览
export const getNotiBoxApi = (params) => {
    return postAction(genV1ApiUrl('msg/box'), params);
}

// 设置已读
export const setMessageRead = (params) => {
    return postAction(genV1ApiUrl('msg/inbox/read'), params);
}
// 获取消息详情接口
export const getMessageDetail = (params) => {
    return postAction(genV1ApiUrl('msg/view'), params);
}
// 获取消息详情接口
export const delMessageApi = (params) => {
    return postAction(genV1ApiUrl('msg/inbox/del'), params);
}

