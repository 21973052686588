<template>
  <div class="nav-header">
    <div class="logo">
      <img style="cursor: pointer;" src="@/assets/images/logo.png" alt="" @click="router.push('/')">
      <span class="version">V1.0</span>
      <span class='dropdown'>
        <span class="el-dropdown-link">
          {{Identity}}
        </span>
      </span>
    </div>
    <div class="content">
     	
		<el-button style="background: #383B48; border-color: #383B48;margin-left:12px;"  type="primary" size="small" @click="router.push('/')">官网首页</el-button>
    <!-- v-if="UserPerm.hasPerm(Constant.PermSell)" -->
		<el-popover 
    	 	placement="bottom" :width="355" trigger="hover" :popper-style="{'padding': '0','border-radius':'10px',}">
			<div class="vipBox">
        <div class="title">
          图布斯会员
        </div>
        <div class="describe">
          开通享特权·终身免费包邮送种子
        </div>
        <div class="listBox">
          <div class="list">
            <img style="width: 34px; height: 27px;" src="@/assets/icon1.png" alt="">
            <div class="text">
              <div class="smallTitle">随心渲染</div>
              <div class="smallText">超清8k 随心渲</div>
            </div>
          </div>
          <div class="list">
            <img style="width: 34px; height: 27px;" src="@/assets/icon2.png" alt="">
            <div class="text">
              <div class="smallTitle">模型素材</div>
              <div class="smallText">海量模型</div>
            </div>
          </div>
          <div class="list">
            <img style="width: 34px; height: 27px;" src="@/assets/icon3.png" alt="">
            <div class="text">
              <div class="smallTitle">任务大厅</div>
              <div class="smallText">众多行业任务</div>
            </div>
          </div>
          <div class="list">
            <img style="width: 34px; height: 27px;" src="@/assets/icon4.png" alt="">
            <div class="text">
              <div class="smallTitle">设计工具</div>
              <div class="smallText">畅享随心设计</div>
            </div>
          </div>
        </div>
        <div class="btn" @click="submitAwait">立即使用</div>
      </div>
      <template #reference>
        <el-button type="primary" size="small" style="background: var(--red);border-color: var(--red);">
          <img style="height: 12px; width: 8px; margin-right: 5px; " src="@/assets/vip.png" alt="">
          开通会员   
        </el-button>
			</template>
		</el-popover>

    <el-button style="margin-left:12px; background: var(--red); border-color: var(--red);"  type="primary" size="small" @click="routerToDesign" v-if="userStatus">开始设计</el-button>

		<el-popover placement="bottom" :width="200" trigger="click" v-else>
			<div class="phoneBox">
			<div class="label">
				<el-icon><Phone /></el-icon>
			</div>
			<div class="value">
				0314-2539999
			</div>
			</div>
			<div class="imgBox">
							<img :src="OSSURL + 'home/sell.png'" alt="">
			</div>
			<template #reference>
			<el-button  type="primary" size="small" style="background: var(--red);border-color: var(--red);">开始设计</el-button>
			</template>
		</el-popover>

    <el-popover placement="bottom" :width="200" trigger="hover">
      <div class="imgBox1">
        <img :src="OSSURL + 'home/sell.png'" alt="">
      </div>
      <template #reference>
        <img class="cus" src="@/assets/cus.png" alt="">
      </template>
    </el-popover>

    <el-popover
			placement="bottom"
			:width="300"
			trigger="hover"
		>

			<div class="popover-content">
				<ul>
					<li v-for="item in messageData" @click="router.push(`/pub/m/${item.msgType == 1 ? 'notice' : 'msg'}`)">
						<el-badge :value="item.total" :max="99" :hidden="!item.total" class="item">
							<img :src="item.img" alt="">
						</el-badge>
						<div class="message-content">
							<p>{{ item.title }}</p>
							<span>{{ item.content }}</span>
						</div>
					</li>
				</ul>
				<el-button type="info" link @click="router.push('/pub/m/notice')">
					查看全部消息
				</el-button>
			</div>

			<template #reference>
				<el-badge :is-dot="!!msgDotCount" :offset="[-3, 3]" :max="99" :hidden="!msgDotCount" class="item">
					<el-button
						style="margin-left:12px; font-size: 20px; color: #fdfdfd"
						type="primary"
						size="small"
						link
						@click="getMessageBox"
					>
						<el-icon ><Bell/></el-icon>
					</el-button>
				</el-badge>
			</template>

		</el-popover>
			<div class="user-container">
			<el-popover placement="bottom" :width="300" trigger="hover" :popper-style="popperStyle">
				<div class="perInfo">
					<div class="header">
					<div class="box">
						<div class="left">
						<img class="perImg" :src="OSSURL + userView?.avatar" alt="">
						</div>
						<div class="right">
						<div class="rightT">
							{{userView?.nickName}}
						</div>
						<div class="rightB">
							ID:  {{userView?.userName}} 
							<span @click="copyId(userView?.userName)">复制ID</span>
						</div>
						</div>
					</div>
					</div>
					<div class="footer">
					<div class="menuList">
						<div class="menuItem" @click="routerToSetting">
						<el-icon><Setting /></el-icon>
						<span>个人中心</span>
						</div>
						<div class="menuItem" @click="buyRecord">
						<el-icon><ShoppingCart /></el-icon>
						<span>购买记录</span>
						</div>
						<div class="menuItem" @click="userLogout">
						<el-icon><CircleClose /></el-icon>
						<span>退出</span>
						
						</div>
					</div>
					</div>
				</div>
				<template #reference>
					<div class="flexDiv">
						<img class="avatar" :src="OSSURL + userView?.avatar" alt="">
						<el-icon><ArrowDown /></el-icon>
					</div>
				</template>
			</el-popover>
      	</div>
    </div>
    <el-dialog
      v-model="buyVisible"
      custom-class="priseDialog"
      :show-close="true"
      width="1000"
      :close-on-click-modal="false"
      :before-close="handleClose"
      >
      <div class="buyDialog">
        <div class="tabsBox">
          <div class="tab">购买记录</div>
        </div>
        <div class="tableBox">
          <el-table :data="dataList" style="width: 100%" :border="false"  v-if="dataList?.length > 0"
            :header-cell-style="{'background': '#45485F'}" :row-class-name="'twoRow'">
            <el-table-column prop="productName" label="商品名称" />
            <el-table-column prop="orderNo" label="订单号">
              <template #default="scope">
                {{scope.row.orderNo}} <span class="copy" @click="copyId(scope.row.orderNo)">复制</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderTime" label="订单时间" />
            <el-table-column prop="payAmount" label="支付金额" width="120" />
            <el-table-column prop="payWay" label="支付方式" width="100"/>
            <el-table-column label="操作" width="100">
              <template #default="scope">
                <el-popover placement="bottom" :width="200" trigger="hover">
                  <div class="imgBox1">
                    <img :src="OSSURL + 'home/sell.png'" alt="">
                  </div>
                  <template #reference>
					          <span style="cursor: pointer;">售后</span>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <div class="empt" v-else>
            <NoData :text="'暂无数据'" 
            :imgStyle="{'width':'150px','height':'150px','margin-top':'30px',}" 
            :textStyle="{'font-size':'22px','margin-top':'30px',}"/>
          </div>
          <div class="pageBox">
            <page 
              :current-page="query.page" 
              :page-size="query.size" 
              :total-items="total"
              @current="handleCurrentChange" 
              @size="handleSizeChange" 
            />
          </div>
        </div>
      </div>
    </el-dialog>
		<PayDialog :payDialog="payDialog" :productDialog="productDialog" :selectItem="selectItem" :sellData="sellPayData" @payClose="payClose" @productClose="productClose"/>
  </div>
</template>

<script setup>
import PayDialog from '@/components/common/payDialog';
import NoData from '@/components/common/noData';
import page from '@/components/common/page';
import UserPerm from "@/constant/userPerm";
import { ref, onMounted } from 'vue'
import Constant from '@/constant/constant';
import PersonPerm from '@/constant/personPerm';
import { useStore } from 'vuex';
import {useRouter} from 'vue-router';
import Cookies from 'js-cookie';
import { OSSURL} from '@/util/file';
import messageBox from '@/constant/message';
import {
	getNotiBoxApi
} from '@/api/notification.js';
import { 
	productSell,
} from '@/api/ware.js';
import { 
	orderBuyList,
} from '@/api/order.js';
	const payDialog = ref(false)
  const productDialog = ref(false)
  const selectItem = ref()
  const sellData = ref([])
  const sellPayData = ref([])
	const payClose = (val) => {
		payDialog.value = val || false
	}
  const productClose = (val) => {
    productDialog.value = val || false
  }
  const query = ref({
    page: Constant.PaginationDefaultPage,
    size: Constant.PaginationDefaultSize,
  })
  const total = ref(0)
  const dataList = ref([])
  const popoverVisible = ref(false)
  const router = useRouter()
  const store = useStore()
  const userView = store.state.user.userView
  const userStatus = PersonPerm.design()
  const Identity = PersonPerm.identityZh()
  const messageData = ref([]);
  const msgDotCount = ref(0);
  const popperStyle = {
    'background': '#3A3C4F',
    'border-color': '#3A3C4F',
    'color': '#FDFDFD',
  }
  const buyVisible = ref(false)
  
  let timer
  onMounted(() => {
    getMessageBox();
  })
  const copyId = (i) => {
    const input = document.createElement('input');
    input.value = i; // 获取需要复制的文本
    document.body.appendChild(input);
    input.select(); // 选中文本
    document.execCommand('copy'); // 执行复制操作
    document.body.removeChild(input); // 移除临时的输入框
    messageBox.success('复制成功')
  }
  const userLogout = () => {
    localStorage.clear()
    Cookies.remove(Constant.HttpAccessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN})
    window.location.href = '/'
  };
  const routerToSetting = () => {
      router.push({ path: '/pub'})
  };

  const routerToDesign = () => {
      window.open(process.env.VUE_APP_DESIGN_URL)
  };
	// 获取导航栏消息概览
	const getMessageBox = () => {
		getNotiBoxApi().then(res => {
			msgDotCount.value = res.total;
			messageData.value = res?.result.map(item=>{
				item.img = item.msgType === 1 ? require(`@/assets/images/notice.png`) : require(`@/assets/images/notification.png`);
				return item;
			});
		});
	}
	//获取table data
	const getDataList = () =>{
		orderBuyList({...query.value}).then((resp) => {
			total.value = resp.total || 0
			dataList.value = resp.result || []
		})
	}
	const submitAwait = () => {
		productDialog.value = true
		getSellDate()

	}
	const handleCurrentChange = (val) => {
		query.value.page = val
		getDataList()
	}
	const handleSizeChange = (val) => {
		query.value.size = val
		getDataList()
	}
	const getSellDate = () => {
    sellPayData.value = []
		productSell().then(res => {
			sellData.value = res.result
      sellData.value.filter(res => {
        if(res.productType != 'product_free') {
          sellPayData.value.push(res)
        }
      })
		});
	}
	const handleClose = () => {
		buyVisible.value = false
	}
	const buyRecord = () => {
		buyVisible.value = true
    getDataList()
	}

</script>
<style lang="scss" scoped>
.copy {
  color: var(--red);
  cursor: pointer;
}
.buyDialog {
	.tabsBox {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		.tab {
			font-size: 26px;
			color: #AE3B34;
		}
	}
}

.pageBox {
	margin-top: 20px;
}
:deep(.el-dialog) {
  border-radius: 20px;
  padding: 24px 30px 40px;
}
.dialogBox {
  color: #fdfdfd;
  font-size: 16px;
  .paymentBox {
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    padding: 25px 20px;
    border: 1px solid #fdfdfd;
    margin-top: 30px;
    border-radius: 12px;
    display: flex;
    .qrImg {
      width: 108px;
      height: 108px;
    }
    .activing {
      filter: blur(5px);
    }
    .iconSize {
      font-size: 70px;
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      cursor: pointer;
    }
    .right {
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .top {
        font-size: 20px;
        margin-top: 10px;
        .money {
          color: #AE3B34;
          font-size: 42px;
          .icon {
            font-size: 29px;
          }
        }
      }
      .bottom {
        .sellImg {
          width: 15px;
          height: 15px;
          display: inline-block;
          margin-left: 5px;
          position: relative;
          top: 2px;
        }
        .rightText {
          display: inline-block;
          font-size: 15px;
          margin-left: 7px;
        }
      }
    }
    .left {
      width: 108px;
      height: 100%;
      background-color: #FBFAFA;
      border-radius: 8px;
      text-align: center;
      position: relative;
      .leftText {
        color: #020202;
        font-size: 10px;
        overflow: hidden;
        .text {
          margin-top: 23px;
        }
        .accord {
          cursor: pointer;
        }
        .btn {
          margin: 0 6px;
          margin-top: 10px;
          background-color: #AE3B34;
          border-radius: 5px;
          height: 18px;
          line-height: 18px;
          font-size: 11px;
          color: #FDFDFD;
          cursor: pointer;
        }
      }
    }
  }
  .productBox {
    display: flex;
    .product {
      width: 100px;
      height:50px;
      box-sizing: border-box;
      border: 1px solid #fdfdfd;
      border-radius: 12px;
      margin-top: 15px;
      margin-right: 30px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
    .productActive {
      border-color: #AE3B34;
    }
  }
  .listBox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    .top {
      font-size: 17px;
      .listTitle {
        display: inline-block;
        overflow:hidden;
        text-overflow:ellipsis;//文本溢出显示省略号
        white-space:nowrap;//文本不会换行
        width: calc(100% - 25px);
        margin-left: 5px;
      }
      img {
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        bottom: 1px;
      }
    }
    .li {
      float: left;
      width: calc(100%);
      overflow:hidden;
      text-overflow:ellipsis;//文本溢出显示省略号
      white-space:nowrap;//文本不会换行
      position: relative;
      padding-left: 15px;
      margin-left: 6px;
      box-sizing: border-box;
      margin-top: 1px;
      .openStatus::before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 8px;
          left: 0px;
          border-radius: 50%;
          background-color: #888991;
      }
    }
    .fillSpan {
        width: 192px;
        height: 0;
    }
    .list {
      width: 192px;
      height: 180px;
      box-sizing: border-box;
      border: 1px solid #FCFCFC;
      border-radius: 12px;
      margin-bottom: 15px;
      padding: 20px;
      cursor: pointer;
    }
    .versionActive {
      border-color: #AE3B34;
    }
  }
  .dialogTitle {
    font-size: 20px;
  }
}
* ul,li {
	list-style: none;
	padding: 0;
}
p {
	margin: 0;
}
.user-container {
  margin-left: 12px;
  .avatar {
    height: 20px;
    width: 20px;
    border-radius: 10px;
  }
  .flexDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.vipBox {
  background: url('@/assets/vipBack.png')  no-repeat;
  background-size: cover;
  padding: 24px 16px;
  border-radius: 10px;
  height: 290px;
  text-align: center;
  .btn {
    line-height: 40px;
    background-color: #AE3B34;
    margin-top: 35px;
    font-size: 18px;
    border-radius: 10px;
	  cursor: pointer;
  }
  .know {
    font-size: 13px;
    margin-top: 8px;
	  cursor: pointer;
  }
  .listBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
    .list {
      width: 156px;
      height: 63px;
      background-color: #C6422D;
      margin-top: 8px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        margin-left: 5px;
        .smallTitle {
          font-size: 18px;
        }
        .smallText {
          font-size: 13px;
          margin-top: 3px;
        }
      }
    }
  }
  .title {
    font-size: 26px;
  }
  .describe {
    font-size: 13px;
    margin-top:5px;
  }
}
.perInfo {
  width: 276px;
  box-sizing: border-box;
  .footer {
    box-sizing: border-box;
    .menuList {
      .menuItem {
        padding: 8px 12px;
        background-color: transparent;
        box-sizing: border-box;
        position: relative;
        user-select: none;
        height: 36px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        border-radius: 8px;
        color: #FDFDFD;
        cursor: pointer;
        border-color: rgb(232, 232, 232);
        border-width: 0px;
        text-decoration: none;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        vertical-align: middle;
          display: flex;
        justify-content: flex-start;
        span {
          margin-left: 10px;
        }
        &:hover {
          background-color: rgba(146,152,155,0.08);
        }
      }
    }
  }
  .header {
    padding: 8px 12px;
    .box {
      display: flex;
      align-items: center;
      .left {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 12px;
          position: relative;
          flex-shrink: 0;
        .perImg {
          width: 50px;
          height: 50px;
          border-radius: 25px;
        }
      }
      .right {
        width: 0;
        flex-grow: 1;
        .rightT {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
        }
        .rightB {
          display: flex;
          align-items: center;
          cursor: pointer;
          span {
            color: var(--red);
            font-size: 12px;
            margin-left: 10px
          }
        }
      }
    }
  }
}
.phoneBox {
	font-size: 14px;
	font-weight: 700;
}
.cus {
  height: 18px;
  width:18px;
  margin-left: 20px
}
.imgBox1 {
	img {
		width: 180px;
	}
}
.imgBox {
	margin-top: 10px;
	img {
		width: 180px;
	}
}
.label {
	display: inline-block;
}
.value {
	display: inline-block;
	padding-left: 10px;
}
.dropdown {
  margin-left: 10px;
}
.version {
  font-size: 8px;
  position: relative;
  top: -5px;
  margin-left: 2px;
  font-weight: 600;
}
</style>

<style lang="scss" >
  .head_box{
    height:calc(var(--headHeight) - 4px);
    box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.4);
  }
 .nav-header{
    height: 60px;
    width: 100%;
    position: fixed;
    color: #FDFDFD;
  //  background-color: var(--btnColor);
    background: #161824;
    box-shadow:0px 0px 20px 0px rgba(0, 0, 0, 1); 
    display: flex;
    justify-content: space-between;
   
  .logo{
    height:calc(100% - 4px);
    padding: 2px 0;
    width: 306px;
    // background-color:var(--btnColor);
    // box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 12;
    img{
      position: relative;
      width: auto;
      height: 25px;
      margin-left: 14px;
    }
    
  }
  .content{
    height: 100%;
    // width: 215px;
   // background-color: aqua;
    margin-right: 20px;
    display: flex;
    align-items: center;
    position: relative;
  }
 }

.popover-content {
	display: flex;
	flex-direction: column;
	ul {
		border-bottom: 1px solid #eee;
	}
	ul li {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
		cursor: pointer;
		.message-content {
			margin-left: 20px;
			p {
				font-weight: bold;
			}
			span {
				font-size: 12px;
				color: rgb(146, 152, 159);
			}
		}
		img {
			width: 40px;
			height: 40px;
			margin-right: 0px;
		}
	}
}
</style>

