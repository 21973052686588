<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
html,
body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: #fafbfe;
	font-family: 'PingFang SC', 'Helvetica', 'Source Han Sans';
}
#app {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.tableBox {
  margin-top: 10px
}
body,ol,ul,li,h1,h2,h3,h4,h5,h6,p,th,td,dl,dd,form,fieldset,legend,input,textarea,select {
    margin: 0;
    padding: 0
}
i,em{
    font-style: normal;
}
b,strong{
    font-weight: normal;
}
ol,ul{
    list-style:none
}
a{
    text-decoration: none;
}
img{
    display: block;
}
 
 
.clearfix:after{
    content: "";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
}

</style>
