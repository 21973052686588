import api from './api'
import constant from '../constant/constant'

class DataFactory {
  // 商家来源渠道
  async DictList(codes) {
    let obj = {}
    for(let i = 0;i<codes.length; i++) {
      const code = codes[i]
      await api.DictCodeList({code: code}).then((resp) => {
        let result = []
        resp.result.forEach(item => {
          result.push({
            key: item.code,
            value: item.name,
          })
        });
        obj[code] = result
      }).catch((err) => {

      });
    }
    return obj
  }
  // 商家名称
  CompanyNames() {
    return api.CompanyList({page: 1, size: 10000}).then((resp) => {
      let result = []
      resp.result.forEach(item => {
        result.push({
          key: item.id,
          value: item.name,
        })
      });
      return result
    }).catch((err) => {
      
    });
  }
  // 账户列表
  UserList() {
    return api.UserList({page: 1, size: 10000}).then((resp) => {
      let result = []
      resp.result.forEach(item => {
        result.push({
          key: item.id,
          value: item.phone,
        })
      });
      return result
    }).catch((err) => {
      
    });
  }
  
  // 账户列表
  RoleList() {
    return api.RoleList({page: 1, size: 10000}).then((resp) => {
      let result = []
      resp.result.forEach(item => {
        result.push({
          key: item.id,
          value: item.name,
        })
      });
      return result
    }).catch((err) => {
      
    });
  }
}

export default new DataFactory()