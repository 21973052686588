
const imgClipQuery = '?x-oss-process=image/resize,m_fill,w_300/rotate,0';
const partnerImgClipQuery = '?x-oss-process=image/resize,m_fill,h_100,w_200/rotate,0';

const excellentDesignData = [
	'home/case/2-240325093912333.jpg',
	'home/case/2-240126154RY09.png',
	'home/case/2-240126142345H9.png',
	'home/case/2-240126142410D2.png',
	'home/case/2-24012511032U28.png',
	'home/case/2-23122Q0363X02.jpg',
	'home/case/2-23122P930435U.jpg',
	'home/case/2-23122G51529346.jpg',
	'home/case/2-23122G443035D.jpg',
	'home/case/2-23122G34554145.jpg',
    'home/case/2-23122G0521YW.jpg',
    'home/case/2-23122G05T03a.jpg',
    'home/case/2-23122G0595X33.jpg',
    'home/case/2-231225105533258.jpg',
    'home/case/2-23122510535X39.jpg',
    'home/case/2-231225093T02D.jpg',
    'home/case/2-23122Q40913435.jpg',
    'home/case/2-2312221F953b1.jpg',
    'home/case/2-23122Q41132B4.jpg',
    'home/case/2-23122Q4151GB.jpg',
    'home/case/2-231222115T0O8.jpg',
    'home/case/2-231222115R4P0.jpg',
    'home/case/2-231222115T0Q4.jpg',
    'home/case/2-23122G20401938.jpg'
];


const partnerData = [
	'home/partner/2-240125092910246.png',
    'home/partner/2-24012509295C61.png',
    'home/partner/2-240125093021256.png',
    'home/partner/2-24012509305QJ.png',
    'home/partner/2-240125093121B4.png',
    'home/partner/2-24012511095O02.png',
    'home/partner/2-240125111043248.png',
    'home/partner/2-24012511110W00.png',
    'home/partner/2-24012511113N55.png',
    'home/partner/2-240125111201412.png',
    'home/partner/2-240125111224105.png',
    'home/partner/2-2401261414092Q.png',
    'home/partner/2-240126141434X1.png',
    'home/partner/2-240126141502560.png',
    'home/partner/2-24012614151b63.png',
    'home/partner/2-240126141540319.png',
    'home/partner/2-240126141601518.png',
    'home/partner/2-240126141624407.png',
    'home/partner/2-240126141644112.png',
    'home/partner/2-240126141924Q1.png',
    'home/partner/2-240126141H05V.png',
    'home/partner/2-240126141Ib50.png',
    'home/partner/2-240126141K6100.png',
    'home/partner/2-240126141S0242.png',
    'home/partner/2-240126141TJ50.png',
    'home/partner/2-240126141ZC27.png',
    'home/partner/2-24012614260a11.png',
    'home/partner/2-240126142A5425.png',
    'home/partner/2-240126142G2205.png',
    'home/partner/2-240126142H5347.png',
    'home/partner/2-240126142IU40.png',
    'home/partner/2-2401261505555W.png',
    'home/partner/2-2401261509103Q.png',
    'home/partner/2-240126150924608.png',
    'home/partner/2-24012615093a19.png',
    'home/partner/2-240126150954557.png',
    'home/partner/2-240126150H1U1.png',
    'home/partner/2-240126150ILL.png',
    'home/partner/2-240126150P1263.png',
    'home/partner/2-240126150Q41E.png',
    'home/partner/2-240126150Rb36.png',
    'home/partner/2-240126150T21V.png',
    'home/partner/2-240126150U5O2.png',
    'home/partner/2-240126151010X0.png',
    'home/partner/2-240126151025L8.png',
    'home/partner/2-240126151039D5.png',
    'home/partner/2-24012615105QB.png',
    'home/partner/2-240126151110205.png',
    'home/partner/2-24012615112CJ.png',
    'home/partner/2-2401261511414K.png',
    'home/partner/2-240126151152207.png',
    'home/partner/2-240126151204U2.png',
    'home/partner/2-2401261512225K.png',
    'home/partner/2-240126151234G8.png'
]
export const excellentDesignDataList = excellentDesignData.map(item=>process.env.VUE_APP_OSSURL + item + imgClipQuery);
export const partnerList = partnerData.map(item=>process.env.VUE_APP_OSSURL + item + partnerImgClipQuery);