<template>
    <el-date-picker 
        v-model="dateRange"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :shortcuts="shortcuts"
        @change="handleChange"
    />
</template>

<script>
export default {
    name: 'DateRangePicker',
    props:['start', 'end'],
    setup(props, context) {
        const shortcuts = [
            {
                text: '最近一周',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                },
            },
            {
                text: '最近一个月',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                },
            },
            {
                text: '最近三个月',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                },
            },
        ]
        return {
            shortcuts,
        }
    },
    watch: {
        start: {
            handler(newVal,oldVal) {
                this.dateRange.splice(0, 1, newVal)
            },
        },
        end: {
            handler(newVal,oldVal) {
                this.dateRange.splice(1, 1, newVal)
            },
        },
    },
    data() {
        return {
            dateRange: [],
        }
    },
    methods: {
        handleChange(e) {
            this.$emit('update:start', e ? e[0] : '')
            this.$emit('update:end', e ? e[1] : '')
        }
    }
}
</script>
