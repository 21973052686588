import {postAction, getAction} from '../manage'
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}
export const getTreeApi = (params) => {
    return postAction(genV1ApiUrl('open/tree/view'), params)
}
export const getModelImageApi = (params) => {
	return postAction(genV1ApiUrl('open/model/page-list'), params)
}
// 根据分类获取文章列表
export const articleList = (params) => {
    return postAction(genV1ApiUrl('article/list'), params)
}
// 查看节点列表
export const treeList = (params) => {
    return postAction(genV1ApiUrl('tree/list'), params)
}
// 文章预览
export const articleDtl = (params) => {
    return postAction(genV1ApiUrl('article/dtl'), params)
}
// 文章目录和内容列表
export const articleNodes = (params) => {
    return postAction(genV1ApiUrl('article/nodes'), params)
}
// 文章有用无用
export const articleLike = (params) => {
    return postAction(genV1ApiUrl('article/like'), params)
}

