import axios from 'axios'
import Constant from '../constant/constant'
import Cookies from 'js-cookie'
import router from '@/router/index.js'
import messageBox from '@/constant/message';
import { ElMessage } from 'element-plus';
const service = axios.create({
  baseURL:'/',
  // 请求超时时长
  timeout: Constant.HttpTimeout,
  // 是否跨站点访问控制请求
  withCredentials: true,
  // 请求头
  headers: {'X-Requested-With': 'XMLHttpRequest'},
  // 请求数据转换
  // transformRequest: [(data) => {
  //   return JSON.stringify(data)
  // }],
  // 响应数据转换
  transformResponse: [(data) => {
    if (typeof data === 'string' && data.startsWith('{')) {
        data = JSON.parse(data)
    }
    return data
  }]
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  // 获取本地token
  const token = localStorage.getItem(Constant.HttpAccessToken)
  // 判断是否存在token，如果存在的话，则每个http header都加上token
  if (token) {
      // 将token设置到请求头中
      config.headers.Authorization = 'Bearer ' + token
  }
  return config;
}, function (error) {
  // 作用： 如果请求失败, 统一放在响应拦截器这里处理 
  error.data = {}
  error.data.msg = '服务器异常，请联系管理员！'
  return Promise.reject(error);
})
// 某些请求白名单不需要进行401处理
const isInWhiteList = (path) => {
	const whiteListPath = Constant.requestWhiteList;
	return whiteListPath.some(item => path.includes(item));
}

// 添加响应拦截器
service.interceptors.response.use((response) => {
  return response.data
}, error => {
  if (error.response) {
      let code = error.response.status
      // let message = error.response.message;
      // if(code === 400) {
      let message = error.response.data;
      // }
      error.message = handleError(code, message);
      if(code === Constant.HttpCode.Code401) {
        if(isInWhiteList(error.config.url)) {
          return;
        }
        localStorage.clear()
        Cookies.remove(Constant.HttpAccessToken,{domain: process.env.VUE_APP_DESIGN_DOMAIN})
        window.location.href = '/';
        messageBox.error(error.message);
      }
	  else {
		messageBox.error(error.message);
	  }

  }
  return Promise.reject(error.message);
})

// 错误码处理
const handleError = (status, defaultMsg) => {
  let message = defaultMsg
  switch (status) {
      case Constant.HttpCode.Code400:
          message = defaultMsg
          break
      case Constant.HttpCode.Code401:
          message = '未授权，请重新登录'
          break
      case Constant.HttpCode.Code403:
          message = '拒绝访问'
          break
      case Constant.HttpCode.Code404:
          message = '页面未找到'
          break
      case Constant.HttpCode.Code408:
          message = '请求超时'
          break
      case Constant.HttpCode.Code500:
          message = '服务器错误'
          break
      case Constant.HttpCode.Code501:
          message = '服务未实现'
          break
      case Constant.HttpCode.Code502:
          message = '网络错误'
          break
      case Constant.HttpCode.Code503:
          message = '服务不可用'
          break
      case Constant.HttpCode.Code504:
          message = '网络超时'
          break
      case Constant.HttpCode.Code505:
          message = 'HTTP版本不受支持'
          break
      default:
          message = `连接出错!`
  }

  return `${message}，请检查！`
}

// 对外暴露
export {
  service as axios
}