<template>
    <div class="infoBox">
        <searchForm :item-list="searchFormList" @search="search" @reset="reset"/>
        <!-- 新增 -->
        <div class="addButton">
          <div class="checkBox">
              <el-checkbox v-model="checkedFile" label="全选" :value="true" size="large"  @change="allChange"/>
          </div>
          <div class="newBtnText">
              已选择{{checkedList.length}}个材质
          </div>
          <BlockBtn :text="'删除'" :textStyle="{'margin-left':'20px','float':'left','padding':'3px 6px','margin-top':'8px'}" @click="allDelete"/>
          <BlockBtn :text="'上传素材'" :textStyle="{'margin-right':'10px','float':'right',}" 
          @click="addDict(activeName)" v-if="UserPerm.hasPerm(Constant.PermMaterialTextureUpload)"/>
            <!-- <el-button class="button" type="primary" @click="addDict(activeName)" v-if="UserPerm.hasPerm(Constant.PermMaterialTextureUpload)">上传材质</el-button> -->
        </div>
        <div class="tableBox">
        <div class="contentBox" v-if="dataList">
            <div class="infoItem" v-for="(i,index) in dataList" :key="index">
                <div class="infoImg">
                    <div class="imgBox">
                        <img :src="OSSURL+i.preview" alt="">
                    </div>
                    <div class="btnBox">
                        <BlockBtn  :text="'编辑'" :textStyle="{'margin-right':'10px','padding':'5px 11px',}" @click="edit(i.id)"/>
                        <BlockBtn  :text="'删除'" :textStyle="{'padding':'5px 11px',}" @click="deleteBtn(i.id)"/>
                    </div>
                </div>
                <div class="infoCheck">
                    <el-checkbox-group v-model="checkedList">
                        <el-checkbox label="" :value="i.id" size="large" />
                    </el-checkbox-group>
                </div>
                <div class="infoText">
                    <el-popover
                      placement="bottom-start"
                      :width="200"
                      trigger="hover"
                      :content="i.name"
                    >
                      <template #reference>
                        <div class="modelTitle">
                          {{i.name}} 
                        </div>
                      </template>
                    </el-popover>
                </div>
            </div>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
            <span class="fillSpan"></span>
        </div>
        <div class="empt" v-else>
            <NoData :text="'暂无数据'" 
            :imgStyle="{'width':'150px','height':'150px','margin-top':'30px',}" 
            :textStyle="{'font-size':'22px','margin-top':'30px',}"/>
        </div>
        <div class="pageBox" v-if="total > 0" >
            <page 
                :current-page="query.page" 
                :page-size="query.size" 
                :total-items="total"
                @current="handleCurrentChange" 
                @size="handleSizeChange" 
            />
        </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            title="编辑信息"
            width="500"
            :before-close="handleClose"
            :close-on-click-modal="false"
        >
            <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"  placeholder="请输入"/>
                </el-form-item>
                <el-form-item label="长度" prop="length">
                    <el-input v-model.number="form.length" type="number" style="width:calc(100% - 40px)" placeholder="请输入"/>
                    <span style="margin-left:10px">mm</span>
                </el-form-item>
                <el-form-item label="宽度" prop="width">
                    <el-input v-model.number="form.width" type="number" style="width:calc(100% - 40px)" placeholder="请输入"/>
                    <span style="margin-left:10px">mm</span>
                </el-form-item>
                <el-form-item label="材质分类" prop="category">
                    <el-cascader v-model="form.category" :options="options" :props="props" clearable 
                    :show-all-levels="false" @change="casChange" style="width:100%;"/>
                </el-form-item>
                <div class="formItemBox" v-for="(item,index) in tagList" :key="index">
                  <el-form-item :label="item.name">
                    <el-select v-model="form['tagList'+index]"  style="width:100%;">
                        <el-option v-for="i in item.children" :key="i.id" :label="i.name" :value="i.id"/>
                    </el-select>
                  </el-form-item>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="onSubmit(ruleFormRef)"
                    >提交</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import BlockBtn from '@/components/common/blockBtn';
import NoData from '@/components/common/noData';
import api from '@/api/api'
import { ref,reactive,computed } from 'vue'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import {useRouter} from 'vue-router'
import { OSSURL} from '../../util/file'
import page from '@/components/common/page';
import dataFactory from '@/api/dataFactory';
import messageBox from '@/constant/message';
import searchForm from '@/components/common/search';
    const prop = defineProps(['activeName','materialType'])
    const rules = reactive({
        category: [{ required: true, message: '分类不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        length: [{ required: true, message: '长度不能为空', trigger: 'blur' }],
        width: [{ required: true, message: '宽度不能为空', trigger: 'blur' }],
    })
    const props = {
        checkStrictly: true,
        children: 'children',
        label: 'nodeName',
        value:'nodeId',
    }
    const tagList = ref([])
    const dialogVisible = ref(false)
    const form = ref({})
    const setForm = ref({})
    const ruleFormRef = ref()
    const options = ref([])
    const modelPosition = ref([])
    const router = useRouter()
    const activeName = ref(prop.activeName)
    const materialType = ref(prop.materialType)
    const loading = ref(false)
    const dataList = ref([])
    const query = ref({
        page: Constant.PaginationDefaultPage,
        size: Constant.PaginationDefaultSize,
        group:materialType.value
    })
    const total = ref(0)
    const checkedFile = ref(false)
    const checkedList = ref([])
    const allChange = (i) => {
      if(i) {
        dataList.value.filter(res => {
          checkedList.value.push(res.id)
        })
      }else {
        checkedList.value = []
      }
    }
    const allDelete = () => {
      if(checkedList.value.length <= 0) {
        messageBox.error('请选择模型!'); 
        return
      }
      messageBox.elMessageBox().then(() => {
        api.TextureBatchDel({ids: checkedList.value}).then((resp) => {
          messageBox.success('删除成功!');
          checkedList.value = []
          getDataList()
        }).catch((err) => {
          ;
        });
      }).catch((err) => {
          messageBox.info('已取消删除!');
      });
    }
    //function
    //获取字典值
    dataFactory.DictList([Constant.DictCodeModelPosition,]).then(res => {
        modelPosition.value = res[Constant.DictCodeModelPosition]
    }).catch((err) => {
        
    });
    //获取分类
    const DictFaList = () =>{
        api.TreeView({bizCode:Constant.TreeTexture,nodeId:'0',}).then((resp) => {
            options.value = resp?.result.children || []
        }).catch((err) => {
            ;
        });
    }
    DictFaList()
    
    //获取资源
    api.TagList({pid: Number(0)}).then((resp) => {
      const arr = resp?.result || []
      arr.forEach(res => {
        if(res.tagType === 1 ) {
          api.TagList({pid: res.id}).then((resp) => {
            res.children = resp.result
            tagList.value.push(res)
          }).catch((err) => {
            ;
          });
        }
      })
    }).catch((err) => {
      ;
    });

    const searchFormList = computed(() => {
        return [
            {label: '材质名称', prop: 'name', type: 'input'},
            {label: '材质分类', prop: 'categoryList', type: 'cascader', list: options.value, props:{...props,multiple: true,}},
        ]
    })
    const search = (form) => {
      let arr = form.categoryList?.map(i => {
        return Number(i)
      })
      setForm.value = {...form,categoryList:arr}
      getDataList()
    }
    const reset = () => {
        query.value.page = Constant.PaginationDefaultPage
        query.value.size = Constant.PaginationDefaultSize
        setForm.value={}
        getDataList()
    }
    const handleCurrentChange = (val) => {
        query.value.page = val
        getDataList()
    }
    const handleSizeChange = (val) => {
        query.value.size = val
        getDataList()
    }
    //模型上传
    const addDict = (activeName) => {
      if(activeName === Constant.TreeTexture) {
        router.push({ path: '/p/material/tu', query: { materialType: materialType.value }})
      }
    }
    //获取列表数据
    const getDataList = () => {
        api.TextureList({...query.value,...setForm.value}).then((resp) => {
          dataList.value = resp.result
          total.value = resp.total
        }).catch((err) => {
            ;
        });
    }
    getDataList()
    //模型分类处理结果
    const edit = (id) => {
      api.TextureView({id:id}).then((resp) => {
        form.value = {
          ...resp,
          category:resp.category+"",
        }
        if(form.value.tagIds?.length>0){
          tagList.value.filter((item,index) => {
            item.children.filter(item1 => {
              if(form.value.tagIds.includes(item1.id)) {
                form.value["tagList"+index] = item1.id
              }
            })
          })
        }
      }).catch((err) => {
          ;
      });
      dialogVisible.value = true
    }
    //删除
    const deleteBtn = (id) => {
        messageBox.elMessageBox().then(() => {
          api.TextureState({id: id, state: Constant.StateDelete}).then((resp) => {
            messageBox.success('删除成功!');
            getDataList()
          }).catch((err) => {
            ;
          });
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }
    //模型分类处理结果
    const casChange = (i) => {
        form.value.category = i.slice(-1)[0]
    }
    //关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
    }
    //确定提交
    const onSubmit = (formEl) => {
        let arrTag = []
        for(let key in form.value) {
          if(key.indexOf('tagList') > -1 && form.value[key]) {
            arrTag.push(form.value[key])
          }
        }
        form.value.tagIds = arrTag
        if (!formEl) return
        formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
            if (valid) {
              form.value = {
                ...form.value,
                category:parseInt(form.value.category),
                price:Number(Number(form.value.price).toFixed(2)),
              }
              api.TextureUpdate({...form.value}).then((resp) => {
                messageBox.success('修改成功!');
                dialogVisible.value = false
                getDataList()
              }).catch((err) => {
                ;
                form.value.category=form.value.category.category+"";
              });
            } else {
                return false
            }
        })
    }
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap {
  background: #fff;
  padding: 0 20px;
}
.right_content {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .infoBox {
    display: flex;
  flex-direction: column;
  }
  .tableBox {
      width: 100%;
      .empt {
        text-align: center;
        font-size: 20px;
        margin-top: 40px;
      }
      .pageBox {
        display: flex;
        margin:20px 0 ;

      }
      .contentBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-left: -20px;
        .fillSpan {
          width: 258px;
          height: 0;
          background: #2B2E42;
          box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
          margin-left: 20px;
          position: relative;
        }
        .infoItem {
          width: 258px;
          height: 260px;
          padding-bottom: 20px;
          background: #2B2E42;
          box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
          margin-top: 20px;
          margin-left: 20px;
          position: relative;
          .infoCheck {
            position: absolute;
            top: 0;
            right: 0;
          }
          .infoImg {
            margin: 20px;
            width: 220px;
            height: 206px;
            border-radius: 4px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .imgBox {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
            .btnBox {
              position: absolute;
              bottom: 10px;
              right: 0;
              padding: 10px 10px 0 0;
              font-size: var(--smallFont);
              flex-direction: row;
              color: #fff;
              display: none;
            }
            .textBox {
              position: absolute;
              top: calc(50% - 12px);
              right: calc(50% - 36px);
              // padding: 10px 10px 0 0;
              font-size: 18px;
              flex-direction: row;
              color: red;
              // display: none;

            }
          }
          .infoText {
            margin-top: 0;
            margin-left: 19px;
            margin-right: 19px;
            overflow: hidden;
            .modelTitle {
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-weight: 700;
              opacity: .8;
              margin-bottom: 10px;
            }
          }
          
          &:hover {
            color: var(--red);
            .infoImg {
                background: rgba(137, 207, 240,.2);
            }
            .btnBox {
              display: flex;
            }
          }
        }
      }
  }
  .header {
    width: 100%;
  }
  .addButton {
    width: 100%;
    .checkBox{
      float: left;
    }
    .newBtnText {
        float: left;
        margin-left: 20px;
        line-height: 40px;
    }
  }
}
</style>
