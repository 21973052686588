
import CryptoJS from 'crypto-js/crypto-js';


const key = CryptoJS.enc.Utf8.parse('1234567890tbsTBS') // 密钥 后端提供
const iv = CryptoJS.enc.Utf8.parse('0123456789tbsTBS') // 偏移量
//加密
export function Encrypt(word) {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
// 解密
export function Decrypt(word) {
    const encrypted = CryptoJS.AES.decrypt(word, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8)
    return encrypted
}
