<template>
    <div class="right_content">
        <div class="header">
            <el-tabs v-model="activeName" class="demo-tabs"  @tab-change="handleClick">
                <el-tab-pane label="模型" :name="Constant.TreeModel" ></el-tab-pane>
                <el-tab-pane label="材质" :name="Constant.TreeTexture" ></el-tab-pane>
            </el-tabs>
            <!-- <el-tabs v-model="materialType" class="demo-tabs" @tab-change="userClick">
                <el-tab-pane v-for="(i,index) in tabList" :key="index" :label="i.label" :name="i.name"></el-tab-pane>
            </el-tabs> -->
            <div class="blockBtnBox">
              <BlockBtn v-for="(i,index) in tabList" :key="index" :class="{'activeBtn':materialType == i.name}" 
              :text="i.label" :textStyle="{'margin-right':'10px',}" @click="userClick(i.name)"/>
            </div>
        </div>
        <div class="infoBox" v-if="activeName === Constant.TreeModel">
            <Model v-if="flag" :activeName="activeName" :materialType="materialType"/>
        </div>
        <div class="infoBox" v-if="activeName === Constant.TreeTexture">
            <Texture v-if="flag" :activeName="activeName" :materialType="materialType" />
        </div>
    </div>
</template>

<script setup>
import BlockBtn from '@/components/common/blockBtn';
import { ref } from 'vue'
import Constant from '@/constant/constant';
import UserPerm from '@/constant/userPerm';
import Model from './model';
import Texture from './texture';
import {useRoute} from 'vue-router'
import PersonPerm from '@/constant/personPerm';
const flag = ref(true)
const route = useRoute()
const userIdentity = PersonPerm.identity()
const tabList = Constant.UserTypeTabs(userIdentity)
const fistActive = tabList?.[0]?.name
const activeName = ref(route.query.active || Constant.TreeModel)
const materialType = ref(fistActive)
//function
//切换选项卡
const handleClick = () => {
  materialType.value = fistActive
}
const userClick = (name) => {
  materialType.value = name
  flag.value = false
  setTimeout(() => {
    flag.value = true
  }, 300)
}
</script>
<style lang="scss" scoped>
.blockBtnBox {
  width: fit-content;
  margin-top: 15px;
  margin-bottom: 30px;
  padding-right: 30px;
}
.right_content {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  min-height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
  }
  .infoBox {
    width: 100%;
  }
}
</style>
