<template>
    <div class="nodata">
        <img 
            class="icon"
            alt=""
            src="@/assets/images/home/noData.png"
            :style="props?.imgStyle"
        >
        <div class="text" :style="props?.textStyle">{{props?.text}}</div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
const props = defineProps(['text','imgStyle','textStyle'])
</script>

<style lang="less" scoped>
.nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon {
        width: 60px;
        height: 60px;
    }
    .text {
        font-size: 20px;
    }
}

</style>
