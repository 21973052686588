<template>
	<div class="material-page">
		<div class="primary">
			<el-tabs v-model="activeName" class="demo-tabs">
				<el-tab-pane v-for="item in modelTree" :label="item.nodeName" :name="item.nodeId"></el-tab-pane>
			</el-tabs>
			<el-tabs class="demo-tabs" v-model="secondaryActivedId">
				<el-tab-pane label="全部" name=""></el-tab-pane>
				<el-tab-pane v-for="item in secondaryList" :label="item.nodeName" :name="item.nodeId"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="image-list">
			<div 
				class="container" 
				v-infinite-scroll="loadImages" 
				:infinite-scroll-immediate="false" 
				:infinite-scroll-distance="0"
				:infinite-scroll-delay="500"
				:infinite-scroll-disabled="loadDisabled"
			>
				<div class="image-wrap" v-for="(item, index) in imagesList" :key="index"  @click="handleClickImage(item.imgUrl)">
					<img :src="item.imgUrl" alt="">
					<p>{{ item.name }}</p>
				</div>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<span class="fillSpan"></span>
				<div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
					<img :src="currImage" >
				</div>
				
				
			</div>
			<div class="page-loading" >
				<img src="@/assets/loading.gif" v-show="listLoading">
			</div>
		</div>
		<el-dialog
			v-model="messShow"
			:show-close="true"
			width="520"
			:before-close="handleClose"
			:close-on-click-modal="false"
			>
			<div class="messBox">
				<div class="messTitle" v-if="design">
					<img src="@/assets/messIcon.png" alt="">
					更多素材请在设计工具中查看，快去设计吧！
				</div>
				<div class="messTitle" v-else>
					<img src="@/assets/messIcon.png" alt="">
					开通会员，畅享全站素材！
				</div>
			</div>
			<div class="btnBox">
				<div class="btnLeft btn" @click="twoRouter">{{ design?'去设计':'去开通' }}</div>
				<div class="btnright btn" @click = "router.push('/p')">返回工作台</div>
			</div>
		</el-dialog>
		<PayDialog :payDialog="payDialog" :productDialog="productDialog" :selectItem="selectItem" :sellData="sellPayData" @payClose="payClose" @productClose="productClose"/>
	</div>
</template>
	
<script setup>
import { productSell,} from '@/api/ware.js';
import PayDialog from '@/components/common/payDialog';
import { useRouter } from 'vue-router';
import Constant from '@/constant/constant';
import {onMounted, ref, computed, watch} from 'vue';
import {getTreeApi, getModelImageApi} from '@/api/login';
import {useStore} from "vuex";
const pageSize = 10;
const imgClipQuery = '?x-oss-process=image/resize,m_fill,w_300/rotate,0';
const currImage = ref('');
const activeName = ref('10');
const secondaryActivedId = ref('');
const modelTree = ref();
const imagesList = ref([]);
const isZoom = ref(false);
const store = useStore()
const currPage = ref(1);
const listLoading = ref(true);
const loadDisabled = ref(false);
const router = useRouter()

const isFullData = ref(false);
const messShow = ref(false);
const design = ref(false)

const isLogin = computed(() => {
	return localStorage.getItem(Constant.HttpAccessToken) || false;
})


const payDialog = ref(false)
const productDialog = ref(false)
const selectItem = ref()
const sellData = ref([])
const sellPayData = ref([])

const getSellDate = () => {
	sellPayData.value = []
		productSell().then(res => {
			sellData.value = res.result
			sellData.value.filter(res => {
			if(res.productType != 'product_free') {
				sellPayData.value.push(res)
			}
		})
	});
}
const twoRouter = () => {
	messShow.value = false
	if(design.value) {
		window.open(process.env.VUE_APP_DESIGN_URL)
	}else {
		productDialog.value = true
		getSellDate()
	}
	
}
const payClose = (val) => {
	payDialog.value = val || false
}
const productClose = (val) => {
	productDialog.value = val || false
}


onMounted(() => {
	getTreeData();
});

watch([()=>activeName.value, () => secondaryActivedId.value], (newVal, oldVal) => {
	isFullData.value = false;
	// 一级分类改变
	if(newVal[0] !== oldVal[0]) {
		secondaryActivedId.value = '';
	}
	getModelImage(newVal[1] || newVal[0]);
});
watch(()=>store.state.user.bfUser, (newVal, oldVal) => {
	if(isLogin.value) {
		if(!newVal.design) {
			store.dispatch('GetInfo').then(res => {
			})
		}else {
			design.value = newVal.design.has
		}
	}
},{ immediate: true });
const handleClickImage = (image) => {
	if(!checkLogin()) {
		return;
	}
	isZoom.value = true;
	currImage.value = image;
	
}
const checkLogin = () => {
	if(!isLogin.value) {
		store.commit('SET_LOGIN_MODAL_VISIBLE', {
			visible: true,
			type: 'wxLogin',
			path: '/materials',
		});
		return false;
	}
	return true
}
const handleClose = () => {
	messShow.value = false
}
const secondaryList = computed(() => {
	return modelTree?.value?.find(item => item.nodeId === activeName.value)?.children?.filter(item => item.state === 1) || [];
})
const getTreeData = () => {
	const params = {
		bizCode: 'model_tree',
		nodeId: '0'
	}
	getTreeApi(params).then(res => {
		modelTree.value = res.result.children?.filter(res => {
			return res.state === 1
		})
	}).catch((err) => {
		
	});
}

const loadImages = () => {
	// 当请求数量满过一次后不再请求
	// if(isFullData.value) {
	// 	return;
	// }
	if(!checkLogin()) {
		return;
	}
	if(design.value) {
		messShow.value = true;
	}else {
		messShow.value = true;
	}
	// listLoading.value = true;
	// const params = {
	// 	categoryList: [activeName.value],
	// 	isHome: 1,
	// 	page: 1,
	// 	size: pageSize
	// }
	// getModelImageApi(params).then(res => {
	// 	if(res?.result?.length) {
	// 		imagesList.value = res?.result?.map(item => {
	// 			item.imgUrl = `${process.env.VUE_APP_OSSURL}${item.preview}${imgClipQuery}`;
	// 			return item;
	// 		}) || [];
			
	// 	}
	// 	// 无数据不再请求
	// 	else {
	// 		loadDisabled.value = true;
	// 	}
	// 	listLoading.value = false;
	// }).catch(err=>listLoading.value = false)
}

const getModelImage = (cateVal) => {
	listLoading.value = true;
	const params = {
		categoryList: [cateVal],
		isHome: 1,
		page: 1,
		size: pageSize
	}
	getModelImageApi(params).then(res => {
		imagesList.value = res?.result?.map(item => {
			item.imgUrl = `${process.env.VUE_APP_OSSURL}${item.preview}${imgClipQuery}`;
			return item;
		}) || [];
		
		listLoading.value = false;
		loadDisabled.value = false;
	}).catch(err=>listLoading.value = false)
}
getModelImage(activeName.value)


</script>
	
<style lang="scss" scoped>
:deep(.el-dialog) {
  border-radius: 20px;
  padding: 24px 30px 40px;
}
.btnBox {
	overflow: hidden;
	margin-top: 30px;
	color: #fdfdfd;
	.btn {
		padding: 8px 20px;
		background-color: #4A4C51;
		border-radius: 5px;
		float: right;
		margin-left: 20px;
		cursor: pointer;
	}
	.btnLeft {
		background-color: var(--red);
	}
}
.messBox {
	color: #fdfdfd;
	.messTitle {
		display: flex;
		align-items: center;
		font-size: 20px;
		img {
			display: inline-block;
			width: 24px;
			height: 24px;
			margin-right: 20px;
		}
	}
}
*ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
$theme-color: #cf3861;
:deep(.el-tabs__item ){
  	color: #fff;
  	&:hover {
		color: $theme-color;
	}
}

:deep(.el-tabs__item.is-active ){
  color: $theme-color;
}

:deep(.el-tabs__active-bar) {
	background-color: $theme-color;
}
:deep(.el-tabs__nav-scroll) {
	display: flex;
	justify-content: center;
}
:deep(.el-tabs__header) {
	// width: 100%;
	// border: none;
}
.demo-tabs {
	display: flex;
	justify-content: center;
	width: 100%;
}
.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	// height: 100%;
	// overflow-y: auto;
	.fillSpan {
		width: 300px;
		height: 0;
		margin: 0 12px;
		margin-top: 20px;
	}
	.image-wrap {
		width: 300px;
		margin: 0 12px;
		margin-top: 20px;
		img {
			background: rgba(255,255,255,.2);
			cursor: pointer;
		}
		>p {
			padding: 5px 0;
			max-height: 48px;
			overflow: hidden;
			line-height: 24px;
			color: #fff;
			text-align: center;
			font-size: 16px;
			background-color: #cf3861;
			border-radius: 16px;
		}
		
	}
}
.zoom-img {
	width:100%;
	height: 100%;
	position:fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,.6);
	img {
		width: 60%;
		max-height: 70%;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		margin: auto;
	}
}
.material-page {
	.image-list {
		padding: 24px;
		box-sizing: border-box;
		.page-loading {
			height: 100px;
			text-align: center;
			>img {
				height: 100%;
			}
		}
	}
}
</style>