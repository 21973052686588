<template>
  <div class="mune">
    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      class="demo-ruleForm"
      status-icon
    >
    <div class="itemBox">
      <el-form-item prop="phone">
        <el-input v-model="form.phone" placeholder="请输入ID" />
      </el-form-item>
    </div>
    <div class="itemBox">
      <el-form-item prop="pass">
        <el-input 
			v-model="form.pass" 
			placeholder="请输入密码"
			type="password" 
			autocomplete="new-password" 
			show-password
			@keyup.enter="submitForm(ruleFormRef)"
		/>
      </el-form-item>
    </div>
    <div class="passBtn">
      <span @click="changeResiter">
        忘记密码
      </span>
    </div>
    <div class="itemBox topStyle">
      <el-form-item>
        <el-button 
			style="width:100%; 
      background-color: #cf3861;
      border: #cf3861;" 
			type="primary" 
			@click="submitForm(ruleFormRef)"
			
        >登录</el-button>
      </el-form-item>
    </div>
    </el-form>
  </div>
</template>

<script setup>
import { reactive,ref,defineEmits, onMounted } from 'vue';
import {useStore} from "vuex";
import { useRouter, useRoute } from 'vue-router';
import {jumpToFromPath} from '@/util/common.js';

const store = useStore()
const emits = defineEmits(['ForgetPassWord']);
const form = ref({})
const ruleFormRef = ref()
const rules = reactive({
    phone: [{ required: true, message: '手机不能为空', trigger: 'blur' }],
    pass: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
})
const changeResiter = () => {
  emits('ForgetPassWord')
}

onMounted(() => {
	window.addEventListener('storage', function(event) {
		if (event.key === 'my_key') {
			console.log('Message received:', event.newValue);
		}
	});
})

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
		store.dispatch('Login',{...form.value,device:1,}).then(res => {
			const path = window.sessionStorage.getItem('loginToPath');
			jumpToFromPath(path);
		})
    } 
  })
}
</script>

<style lang="less" scoped>
.mune {
  height: 100%;
}
.itemBox {

}
.demo-ruleForm {
  margin-top: 30px;
  height: 100%;
}
.passBtn {
  text-align: right;
  padding: 10px 0;
  font-size: 12px;
  color: var(--red);
  cursor: pointer;
}
.topStyle {
	margin-top: 30px;
}
</style>
