<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: "schemeManage",
  components: {}
};
</script>
<style>
</style>
