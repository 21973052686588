

/**
 * 生成唯一id
 * @param  {Number} length 要生成的id长度
 * @return {Object}
 */
export const genID = ( length ) => {
    return Number( Math.random().toString().substr( 3, length ) + Date.now() ).toString( 36 )
}
// 根据地址栏参数from跳转，如果有入参，跳转入参地址
export const jumpToFromPath = (path) => {
	const params = new URLSearchParams(window.location.search);
	const fromPath = params.get('from');
	if(fromPath ){
		return window.location.href = fromPath;
	}
	if(path && typeof path === 'string') {
		return window.location.href = path;
	}
	window.location.reload();
	
}
export const debounce = (func, delay = 1000) => {
	let timeoutId;
	return (...args) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

