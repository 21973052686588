<template>
  <div class="nav-header">
    <div class="headerBox">
        <div class="logo">
            <img style="cursor: pointer;" src="@/assets/images/logo.png" alt="" @click="router.push('/')">
            <div class="icon">|</div>
            <div class="title">
                个人设置
            </div>
        </div>
        <div class="returnBtn" @click="router.push('/p')">
            <el-icon><Back /></el-icon>
            返回工作台
        </div>
    </div>
  </div>
</template>

<script setup>
import { reactive,ref,defineEmits } from 'vue'
import { OSSURL} from '@/util/file'
import messageBox from '@/constant/message'
import api from '@/api/api'
import {Encrypt} from '@/constant/crypto'
import {useStore} from "vuex"
import { useRouter } from 'vue-router'
import Constant from '@/constant/constant';
import Cookies from 'js-cookie';
    const router = useRouter()
</script>
<style lang="scss" scoped>
 .nav-header{
    height: 60px;
    width: 100%;
	background: #161824;
	box-shadow: 5px 6px 11px 5px rgba(14, 14, 14, 0.8);
    display: flex;
    justify-content: space-between;
    .headerBox {
        width: 100%;
        padding: 0 10%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        .returnBtn {
            display: flex;
            align-items: center;
            color: var(--red);
            cursor: pointer;
        }
        .icon {
            margin-left: 20px;
        }
        .title {
            margin-left: 20px;
            font-weight: 600;

        }
        .logo{
                height:calc(100% - 4px);
                padding: 2px 0;
                width: 406px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                z-index: 12;
            img{
                position: relative;
                width: auto;
                height: 25px;
                margin-left: 14px;
            }
            
        }
    }
 }
</style>

