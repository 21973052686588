<template>
  <div class="sidebar_box">
    <div class="sidebarHeader">
        <div class="box">
            <div class="left">
                <img class="perImg" :src="OSSURL + userView?.avatar" alt="">
            </div>
            <div class="right">
                <div class="rightT">
                    {{userView?.nickName}}
                </div>
                <div class="rightB">
                    ID:{{userView?.userName}}
                </div>
            </div>
        </div>
    </div>
     <el-menu :default-active="path" class="el-menu-vertical-demo" background-color="#161824" text-color="#686870" active-text-color="#FDFDFD">
        <span  v-for="(item, index) in pathList" :key="index">
			
          <!-- 不存在children -->
          <template v-if="!item.children?.length">
            <router-link :to="'/'+item.path" :key="item.path" >
              <el-menu-item
                :index="'/'+item.path"
              >
                <template #title>
                  <!-- <el-icon>
                    <component :is="iconName(item.code)"></component>
                  </el-icon> -->
                  <span>{{ item.label }}</span>
                </template>
              </el-menu-item>
            </router-link>
          </template>
          <template v-else>
            <el-sub-menu :index="item.path">
              <template #title>
                <!-- <el-icon>
                  <component :is="iconName(item.code)"></component>
                </el-icon> -->
                <span>{{ item.label }}</span>
              </template>
              <span v-for="ele in item.children" :key="ele.path">
                <router-link
                  :to="'/'+item.path+'/'+ele.path"
                  :key="ele.meta.title">
                  <el-menu-item :index="'/'+item.path+'/'+ele.path">
                    <span>{{ ele.meta.title }}</span>
                  </el-menu-item>
                </router-link>
              </span>
            </el-sub-menu>
          </template>
        </span>
      </el-menu>
  </div>
</template>

<script setup>
import Constant from '@/constant/constant';
import { useRouter,useRoute } from 'vue-router';
import { ref ,computed} from 'vue'
import { useStore } from 'vuex';
import { OSSURL} from '@/util/file'
    const store = useStore()
    const userView = computed(() => store.state.user.userView)
    const router = useRouter().options?.routes?.find(item=>item.name === 'person')?.children;
    const { path } = useRoute()
    const pathList = ref([])
    const menuList = ref([])
    menuList.value = Constant.MenuList()
    router.map(res => {
      if(res.path === '/' || res.path === '/login') {
        return
      }
      if(res.children?.length>0) {
        pathList.value.push({
          label:res.meta.title,
          path:'pub/' + res.path,
          code:res.meta.code,
          children:res.children
        })
      }else {
        pathList.value.push({
          label:res.meta.title,
          path:'pub/' +  res.path,
          code:res.meta.code,
          children:[]
        })
      }
    })
    //function
    const iconName = (code) => {
      let icon = ""
      menuList.value.forEach((item) => {
        if (code === item.code) {
          icon = item.icon
        }
      })
      return icon
    };
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
.el-menu {
    border-right: none;
}
.sidebar_box {
    padding: 0 10px;
    width: 240px;
    overflow-y: auto;
    background: #161824;
    box-shadow: 5px 6px 11px 5px rgba(14, 14, 14, 0.8);
    border-radius: 10px;
    color: #fdfdfd;
    .sidebarHeader {
        padding: 8px 12px;
        .box {
            display: flex;
            align-items: center;
            cursor: pointer;
            .left {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 12px;
                position: relative;
                flex-shrink: 0;
                .perImg {
                    width: 50px;
                    height: 50px;
                    border-radius: 25px;
                }
            }
            .right {
                width: 0;
                flex-grow: 1;
                .rightT {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 600;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .rightB {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
