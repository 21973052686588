<template>
    <img 
        class="icon"
        alt=""
        :src="hover || props?.iconObj?.checked ? props?.iconObj?.icon2 : props?.iconObj?.icon" 
        :style="props?.styleObject"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
    >
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
const props = defineProps(['iconObj','styleObject'])
// iconObj = {
//     icon     非选中
//     icon2    选中
//     checked  是否默认选中
// }
const hover = ref(false)
const handleMouseOver = () => {
hover.value = true
}
const handleMouseLeave = () => {
hover.value = false
}
</script>

<style lang="less" scoped>
.icon {
    width: 40px;
    height: 40px;
}

</style>
