/**
 * 专门处理store的数据
 */
import store from '@/store'
import Constant from './constant'
const PersonPerm = {
    //设计权限
    design() {
        return store.state.user.bfUser.design?.has
    },
    //点击去设计的身份
    toDesign() {
        return '暂无权限'
    },
    //用户类型
    identityZh() {
        return Constant.UserType(store.state.user.bfUser.user?.userType) || ''
    },
    //用户类型
    identity() {
        return store.state.user.bfUser.user.userType
    }
    
}

export default PersonPerm