<template>
    <div class="right_content">
        <div class="footerBox">
            <div class="searchValueBox">
                <el-input v-model="searchValue" @change="planSearch" style="width: 240px; background: #383B48;" placeholder="搜索方案" clearable size="large">
                    <template #prefix>
                        <el-icon style="cursor: pointer;" @click="planSearch" class="el-input__icon"><search /></el-icon>
                    </template>
                </el-input>
            </div>
            <div class="btnInfo">
                <div class="leftBtn">
                    <div class="text">
                        文件夹：
                    </div>
                    <el-popover placement="bottom" :width="100" trigger="hover" :popper-style="popperStyle">
                        <div class="perInfo">
                            <div class="footer">
                                <div class="menuList">
                                    <div class="menuItem" @click="getALLChild()">
                                        <span>全部</span>
                                    </div>
                                    <div class="menuItem" v-for="item in fatherList" :key="item.id" @click="showChild(item)">
                                        <span>{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template #reference>
                            <div class="outBtn">
                                <span class="span">{{ activeName || '全部' }}</span>
                                <span class="iconSpan"><el-icon><ArrowDown /></el-icon></span>
                            </div>
                        </template>
                    </el-popover>
                    <div class="text">
                        排序：
                    </div>
                    <el-popover placement="bottom" :width="100" trigger="hover" :popper-style="popperStyle1">
                        <div class="perInfo">
                            <div class="footer">
                                <div class="menuList">
                                    <div class="menuItem" @click="timeClick(1)">
                                        <div class="menuItemTitle">
                                            更新时间
                                        </div>
                                        <div class="menuItemIcon">
                                            <el-icon @click="sortClick('asc')" :class="{'active': timeActive==1 && sortType == 'asc'}" class="top"><CaretTop /></el-icon>
                                            <el-icon @click="sortClick('desc')" :class="{'active': timeActive==1 && sortType == 'desc'}" class="bottom"><CaretBottom /></el-icon>
                                        </div>
                                    </div>
                                    <div class="menuItem" @click="timeClick(2)">
                                        <div class="menuItemTitle">
                                            创建时间
                                        </div>
                                        <div class="menuItemIcon">
                                            <el-icon @click="sortClick('asc')" :class="{'active': timeActive==2 && sortType == 'asc'}" class="top"><CaretTop /></el-icon>
                                            <el-icon @click="sortClick('desc')" :class="{'active': timeActive==2 && sortType == 'desc'}" class="bottom"><CaretBottom /></el-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template #reference>
                            <div class="outBtn">
                                <span class="span">{{ timeActiveName }}</span>
                                <span class="iconSpan"><el-icon><ArrowDown /></el-icon></span>

                            </div>
                        </template>
                    </el-popover>
                </div>
                <div class="rightBtn">
                    <div class="newBtn" v-if="!showCheck" @click="allEdit">
                        批量编辑
                    </div>
                    <div class="newBtn" @click="allEdit" v-if="showCheck">
                        完成
                    </div>
                    <div class="newBtn" @click="allMoveToFile" v-if="showCheck">
                        移动到
                    </div>
                    <div class="newBtn" @click="allEditOp(1)" v-if="showCheck">
                        删除
                    </div>
                    <div class="newBtnText" v-if="showCheck">
                        已选择{{editList.length}}个方案
                    </div>
                    <div class="newBtn" @click="AddFloder = true">
                        <el-icon class="iconFont"><FolderAdd /></el-icon>
                        新建文件夹
                    </div>
                    <div class="line1"></div>
                    <div class="newImg" :class="{'isActive':isActive==2}" @click="selectActive(2)">
                        <img class="iconImg" src="@/assets/images/plan/list.png" alt="">
                    </div>
                    <div class="newImg" :class="{'isActive':isActive==1}"  @click="selectActive(1)">
                        <img class="iconImg" src="@/assets/images/plan/block.png" alt="">
                    </div>
                    <div class="line1"></div>
                    <div class="checkBox">
                        <el-checkbox v-model="checkedFile" style="font-size: 14px;"  label="铺开文件夹" :value="true" size="large" />
                    </div>
                </div>
            </div>
            <div class="fileInfo" v-if="checkedFile">
                <div class="files">
                    <!-- :class="active == file.id" -->
                     <span v-for="file in fatherList" :key="file.id">
                        <div class="file"  @click="showChild(file)"  :class="active === file.id?'active':''">
                            <img style="width: 89px; height: 75px;" src="@/assets/images/plan/file.png" alt="">
                            <span class="fileText">{{ file.name }}</span>
                            <el-popover placement="bottom" :width="100" trigger="hover" :popper-style="popperStyle" :show-arrow="false">
                                <div class="perInfo">
                                    <div class="footer">
                                        <div class="menuList">
                                            <div class="menuItem" @click="editFolder(file)">
                                                <span>重命名</span>
                                            </div>
                                            <div class="menuItem" @click="folderDelete(file.id,Constant.StateDelete)">
                                                <span>删除</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template #reference>
                                    <WhiteBtn class="whiteBtn" :text="'···'" :textStyle="{'position': 'absolute', 'top': '10px', 'right': '10px',}"/>
                                </template>
                            </el-popover>
                        </div>
                     </span>
                </div>
            </div>
            <div class="rightInfo" v-show="isActive == 1">
                <div class="tableBox">
                    <div class="contentBox" v-if="dataList">
                        <div class="infoItem" v-for="(i,index) in dataList" :key="index">
                            <div class="infoImg">
                                <div class="imgBox">
                                    <img :src="OSSURL+i.preview" alt="">
                                </div>
                                <div class="designBtn">
                                    <el-button type="" @click="routerToDesign(i)">去设计</el-button>
                                </div>
                            </div>
                            <div class="infoText">
                                <div class="tubusi">
                                    图布斯
                                    <span>v{{i.version || '1.0'}}</span>
                                </div>
                                <div class="tplType" v-if="i.tplType">
                                    <span>{{i.tplType}}</span>
                                </div>
                                <el-tooltip :content="i.name" placement="bottom">
                                    <div class="modelTitle">
                                        {{i.name}}
                                    </div>
                                </el-tooltip>
                                <div class="modelTime">
                                    {{i.designTime}} 
                                </div>
                            </div>
                            <div class="infoCheck" v-if="showCheck">
                                <el-checkbox-group v-model="checkedList">
                                    <el-checkbox label="" :value="i.id" @change="(val) => allEditChange(val,i)" size="large" />
                                </el-checkbox-group>
                            </div>
                            <div class="infoText">
                                <WhiteBtn :text="'查看详情'" @click="edit(i.id)"/>
                                <el-popover placement="top" :width="100" trigger="hover" :popper-style="popperStyle" :show-arrow="false">
                                    <div class="perInfo">
                                        <div class="footer">
                                            <div class="menuList">
                                                <div class="menuItem"  @click="editPlanInfo(i.id)">
                                                    <span>编辑方案信息</span>
                                                </div>
                                                <div class="menuItem" @click="projectCopy(i.id)">
                                                    <span>创建副本</span>
                                                </div>
                                                <!-- <div class="menuItem" @click="userLogout">
                                                    <span>分享方案</span>
                                                </div> -->
                                                <div class="line"></div>
                                                <div class="menuItem" @click="moveToFile(i.id)">
                                                        <span>移至文件夹</span>
                                                    </div>
                                                <div class="menuItem" @click="deleteBtn(i.id)">
                                                    <span>删除</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template #reference>
                                        <WhiteBtn :text="'···'" :textStyle="{'margin-left': '10px'}"/>
                                    </template>
                                </el-popover>
                            </div>
                        </div>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                        <span class="fillSpan"></span>
                    </div>
                    <div class="empt" v-else>
                        <NoData :text="'暂无数据'" 
                        :imgStyle="{'width':'150px','height':'150px','margin-top':'30px',}" 
                        :textStyle="{'font-size':'22px','margin-top':'30px',}"/>
                    </div>
                    <div class="pageBox" v-if="total > 0" >
                        <page 
                            :current-page="query.page" 
                            :page-size="query.size" 
                            :total-items="total"
                            @current="handleCurrentChange" 
                            @size="handleSizeChange" 
                        />
                    </div>
                </div>
            </div>
            <div class="rightInfo"  v-show="isActive == 2">
                <div class="tableBox">
                    <el-table v-loading="loading" :data="dataList" style="width: 100%" v-if="dataList?.length > 0"
                    :header-cell-style="{'background': '#45485F'}" :row-class-name="tableRowClassName"
                     @selection-change="handleSelectionChange" ref="multipleTableRef" >
                        <el-table-column type="selection" width="55" v-if="showCheck"  />
                        <el-table-column prop="name" label="名称">
                            <template #default="scope">
                                <img :src="OSSURL+scope.row.preview" style="width: 30px; height: 30px; display: inline-block; position: relative; top: 5px;" alt="">
                                <span style="margin-left: 10px; position: relative; bottom: 3px;">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="designTime" label="更新时间" />
                        <el-table-column label="操作" width="300">
                            <template #default="scope">
                                <div class="infoTextTable">
                                    <WhiteBtn @click="edit(scope.row.id)" :text="'查看详情'" :textStyle="{'margin-left': '10px',}" v-if="scope.row.preview" />
                                    <!-- <div class="lookBtn" @click="edit(scope.row.id)">
                                        查看详情 
                                    </div> -->
                                    <el-popover placement="top"  :width="100" trigger="hover" :popper-style="popperStyle" :show-arrow="false"  v-if="scope.row.preview">
                                        <div class="perInfo">
                                            <div class="footer">
                                                <div class="menuList">
                                                    <div class="menuItem" @click="editPlanInfo(scope.row.id)">
                                                        <span>编辑方案信息</span>
                                                    </div>
                                                    <div class="menuItem" @click="projectCopy(scope.row.id)">
                                                        <span>创建副本</span>
                                                    </div>
                                                    <!-- <div class="menuItem" @click="userLogout">
                                                        <span>分享方案</span>
                                                    </div> -->
                                                    <div class="line"></div>
                                                    
                                                    <div class="menuItem" @click="moveToFile(scope.row.id)">
                                                        <span>移至文件夹</span>
                                                    </div>
                                                    <div class="menuItem" @click="deleteBtn(scope.row.id)">
                                                        <span>删除</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <template #reference>
                                            <WhiteBtn :text="'···'" :textStyle="{'margin-left': '10px',}" />
                                        </template>
                                    </el-popover>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="empt" v-else>
                        <NoData :text="'暂无数据'" 
                        :imgStyle="{'width':'150px','height':'150px','margin-top':'30px',}" 
                        :textStyle="{'font-size':'22px','margin-top':'30px',}"/>
                    </div>
                    <div class="pageBox" v-if="total > 0" >
                        <page 
                            :current-page="query.page" 
                            :page-size="query.size" 
                            :total-items="total"
                            @current="handleCurrentChange" 
                            @size="handleSizeChange" 
                        />
                    </div>
                </div>
            </div>
            <el-dialog
                v-model="floderVisible"
                title="修改分组名称"
                width="300"
                :before-close="handleClose"
                :close-on-click-modal="false"
            >
                <el-input v-model="groupName"  placeholder="请输入"/>
                <template #footer>
                    <span class="dialog-footer">
                        <WhiteBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="handleClose"/>
                        <WhiteBtn :text="'确认'" :textStyle="{}" @click="keyDownRename"/>
                    </span>
                </template>
            </el-dialog>
            <el-dialog
                v-model="AddFloder"
                title="新建文件夹"
                width="300"
                :before-close="handleClose"
                :close-on-click-modal="false"
            >
                <el-input v-model="groupName"  placeholder="请输入"/>
                <template #footer>
                    <span class="dialog-footer" style="overflow: hidden">
                        <WhiteBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="handleClose"/>
                        <WhiteBtn :text="'确认'" :textStyle="{}" @click="keyDownRename(1)"/>
                    </span>
                </template>
            </el-dialog>
            
            <el-dialog
                v-model="moveFloder"
                title="选择目标文件夹"
                width="400"
                :before-close="handleClose"
                :close-on-click-modal="false"
            >
                <div class="selectFolder" v-for="item in fatherList" :key="item.id" @click="selectFolderClick(item.id)"
                 :class="{'selectFolderActive':selectFolder==item.id}" >
                    <img src="@/assets/images/plan/file.png" style="width: 38px; height: 32px; display: inline-block; position: relative; top: 5px;" alt="">
                    <el-tooltip :content="item.name" placement="bottom">
                        <span>{{ item.name }}</span>
                    </el-tooltip>
                </div>
                <template #footer>
                    <span class="dialog-footer" style="overflow: hidden">
                        <WhiteBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="handleClose"/>
                        <WhiteBtn :text="'确认'" :textStyle="{}" @click="moveToFolder"/>
                    </span>
                </template>
            </el-dialog>
            <el-dialog
                v-model="dialogVisible"
                title="编辑信息"
                width="500"
                :close-on-click-modal="false">
                <el-form :model="formList" label-width="120px" ref="ruleFormRef" :rules="rules">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="formList.name"  placeholder="请输入"/>
                    </el-form-item>
                    <el-form-item label="方案文件夹" prop="folderId">
                        <el-select v-model="formList.folderId"  style="width:100%;">
                            <el-option v-for="i in fatherList" :key="i.id" :label="i.name" :value="i.id"/>
                        </el-select>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <BlockBtn :text="'取消'" :textStyle="{'margin-right':'10px',}" @click="handleClose"/>
                        <BlockBtn :text="'提交'" :textStyle="{}" @click="onSubmit(ruleFormRef)"/>
                        <!-- <el-button @click="dialogVisible=false">取消</el-button>
                        <el-button type="primary" @click="onSubmit(ruleFormRef)"
                        >提交</el-button> -->
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
import BlockBtn from '@/components/common/blockBtn';
import NoData from '@/components/common/noData';
import WhiteBtn from '@/components/common/whiteBtn';
import api from '@/api/api'
import { ref,reactive } from 'vue'
import messageBox from '@/constant/message';
import Constant from '@/constant/constant';
import { OSSURL} from '../../util/file'
import page from '@/components/common/page';
import searchForm from '@/components/common/search';
import {useRouter} from 'vue-router'
import { useStore } from 'vuex';
import PersonPerm from '@/constant/personPerm';
const vue = useStore()
const searchValue = ref()
const userStatus = PersonPerm.design()
const toDesign = PersonPerm.toDesign()
const userId = vue.state.user.userView.id
const loading = ref(false)
const router = useRouter()
const fatherList = ref([])
const groupName = ref(null)
const floderVisible = ref(false)
const active = ref(null)
const activeName = ref(null)
const folderId = ref(null)
const moveRefList = ref([]);
const dataList = ref([])
const total = ref(0)
const checkedFile = ref(false)
const isActive = ref(1)
const AddFloder = ref(false)
const moveFloder = ref(false)
const selectFolder = ref(null)
const planId = ref(null)
const showCheck = ref(false)
const dialogVisible = ref(false)
const editList = ref([])
const checkedList = ref([]);
const multipleTableRef = ref()
const number = ref('one')
const query = ref({
    page: Constant.PaginationDefaultPage,
    size: 12,
    sortType:1,
    sort:'desc',
})
const formList = ref({})
const ruleFormRef = ref()
const rules = reactive({
    folderId: [{ required: true, message: '分类不能为空', trigger: 'change' }],
    name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
})
const popperStyle = {
    'background': '#3A3C4F',
    'border-color': '#3A3C4F',
    'color': '#FDFDFD',
    'min-width': '88px',
    'width': 'auto',
    'cursor': 'pointer',
}
const popperStyle1 = {
    'background': '#3A3C4F',
    'border-color': '#3A3C4F',
    'color': '#FDFDFD',
    'min-width': '110px',
    'cursor': 'pointer',
}
const timeActiveName = ref('更新时间')
const timeActive = ref(1)
const sortType = ref('desc')
const timeClick = (i) => {
    timeActive.value = i
    query.value.sortType = i
    if(i === 1) {
        timeActiveName.value = '更新时间'
    }else {
        timeActiveName.value = '创建时间'
    }
    getDataList()
}
const sortClick = (i) => {
    sortType.value = i
    query.value.sort = i
}
const tableRowClassName = ({row,rowIndex}) => {
    if (!row.preview) {
        return 'fileRow'
    } else if (rowIndex % 2 !== 0) {
        return 'oneRow'
    } else {
        return 'twoRow'
    }
}

const allEditChange = (i,j) => {
    console.log(checkedList.value)
    if(i) {
        editList.value.push(j)
    }else {
        let index = editList.value.findIndex(item => item.id == j.id)
        editList.value.splice(index,1)
    }
}
const allEdit = () => {
    showCheck.value = !showCheck.value
    editList.value = []
    checkedList.value = []
    multipleTableRef.value.clearSelection()
}
const allEditOp = (i) => {
    if(editList.value.length <= 0) {
        messageBox.error('请选择方案!'); 
        return
    }
    let batchList = []
    editList.value.filter(res => {
        batchList.push({
            folderId:selectFolder.value,
            op:i,
            projectId:res.id,
        })
    })
    if(i==1) {
        messageBox.elMessageBox().then(() => {
            api.ProjectOp({batchList:batchList}).then((resp) => {
                editList.value = []
                checkedList.value = []
                messageBox.success('删除成功!');
                getDataList()
            }).catch((err) => {

            });
        }).catch((err) => {
            messageBox.info('已取消删除!');
        });
    }else {
        api.ProjectOp({batchList:batchList}).then((resp) => {
            editList.value = []
            checkedList.value = []
            messageBox.success('移动成功!');
            moveFloder.value = false
            selectFolder.value = null
            getDataList()
        })
    }
}
const editPlanInfo = (id) => {
    api.ProjectView({id:Number(id)}).then((resp) => {
        dialogVisible.value = true
        formList.value = resp
    })
}
const selectActive = (i) => {
    isActive.value = i
    if(i == 1 && showCheck.value) {
        editList.value.filter(res => {
            checkedList.value.push(res.id)
        })
    }
    if(i == 2 && showCheck.value) {
        toggleSelection(editList.value)
    }
}

const toggleSelection = (rows) => {
  if (rows) {
    rows.forEach((row) => {
      multipleTableRef.value.toggleRowSelection(row, true)
    })
  } else {
    multipleTableRef.value.clearSelection()
  }
}
const getALLChild = () => {
    active.value = null
    activeName.value = null
    query.value.folderId = null
    getDataList()

}
const showChild = (i) => {
    active.value = i.id
    activeName.value = i.name
    query.value.folderId = i.id
    getDataList()
}
const TagFaList = () =>{
    api.FolderList().then((resp) => {
        fatherList.value = resp?.result || []
        query.value = {
            ...query.value,
        }
        getDataList()
    }).catch((err) => {
    });
}
TagFaList()
const planSearch = () => {
    getDataList({name:searchValue.value})
}
//获取列表数据
const getDataList = (form) => {
    api.ProjectList({...query.value,...form}).then((resp) => {
        dataList.value = resp.result
        total.value = resp.total
    }).catch((err) => {
    });
}
const edit = (id) => {
    router.push({ path: '/p/project/sm', query: { id: id }})
}

const projectCopy = (id) => {
    messageBox.elMessageBox('确认创建？').then(() => {
        api.ProjectCopy({projectId:id}).then((resp) => {
            console.log(resp)
            messageBox.success('创建成功!');
            getDataList()
        }).catch((err) => {
        ;
        });
    }).catch((err) => {
        messageBox.info('已取消创建!');
    });

}
const moveToFile = (id) => {
    number.value = 1
    planId.value = id
    moveFloder.value = true
}
const allMoveToFile = () => {
    number.value = 2
    if(editList.value.length > 0) {
        moveFloder.value = true
    }else {
        messageBox.error('请选择方案!'); 
    }
}
//移动
const moveToFolder = () => {
    if(!selectFolder.value) {
        messageBox.error('请选择文件夹!'); 
        return
    }
    // 批量处理
    if(number.value == 2) {
        allEditOp(2)
    }else {
        // 单个处理
        api.ProjectMove({folderId: selectFolder.value, id: planId.value}).then((resp) => {
            messageBox.success('移动成功!');
            getDataList()
            moveFloder.value = false
            selectFolder.value = null
            planId.value = null
        })
    }
}
const selectFolderClick = (id) => {
    selectFolder.value = id
}
//删除
const deleteBtn = (id) => {
    messageBox.elMessageBox().then(() => {
        api.ProjectState({id: id, state: Constant.StateDelete}).then((resp) => {
        messageBox.success('删除成功!');
        getDataList()
        }).catch((err) => {
        ;
        });
    }).catch((err) => {
        messageBox.info('已取消删除!');
    });
}
const folderDelete = (id,state) => {
    messageBox.elMessageBox().then(() => {
        api.FolderState({id:id,state:state}).then((res) => {
            messageBox.success('修改成功')
            TagFaList()
            // folderRefRefList.value[id].hide()
        }).catch((err) => {
            
        });
    }).catch((err) => {
        messageBox.info('已取消删除!');
    });
}
const editFolder = (item) => {
    console.log(item)
    groupName.value = item.name
    folderId.value = item.id
    floderVisible.value = true
}
const keyDownRename = (i) => {
    if(!groupName.value) {
        messageBox.error('请填写分组名称')
        return
    }
    let params = {}
    if(i === 1) {
        params = {
            name:groupName.value,
            id: 0
        }
    }else {
        params = {
            name:groupName.value,
            id: folderId.value || null
        }
    }
    api.FolderSave(params).then((resp) => {
        handleClose()
        messageBox.success('修改成功')
        TagFaList()
    }).catch((err) => {
        
    });

}
const handleClose = () => {
    folderId.value = null
    groupName.value = null
    floderVisible.value = false
    AddFloder.value = false
    moveFloder.value = false
    dialogVisible.value = false
    selectFolder.value = null
    planId.value = null
}
const handleCurrentChange = (val) => {
    query.value.page = val
    getDataList()
}
const handleSizeChange = (val) => {
    query.value.size = val
    getDataList()
}
const routerToDesign = (i) => {
    if(userStatus) {
        window.open(process.env.VUE_APP_DESIGN_URL+`?id=${i.id}&user_id=${userId}`)
    }else {
        messageBox.error(toDesign)
    }
};
const handleSelectionChange = (i) => {
    editList.value = i
}
//确定提交
const onSubmit = (formEl) => {
    if (!formEl) return
    formEl.validate((valid) => {//对表单进行一个校验，接收一个回调函数，返回promise
        if (valid) {
            api.ProjectUpdate(formList.value).then((resp) => {
                handleClose()
                getDataList()
            }).catch((err) => {
            });
        } else {
            return false
        }
    })
}
</script>
<style lang="scss" scoped>
.selectFolderActive {
    background-color: #262833;
}
.selectFolder {
    line-height: 40px;
    padding: 5px 20px;
    margin: 10px 0;
    border-radius: 5px;
    overflow: hidden;
    img {
        float: left;
    }
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
        width: calc(100% - 50px);
        float: left;
    }
    &:hover {
        background-color: #262833;
    }
}
.folderBtn {
    text-align: right;
    margin-top: 10px;
}
.folderBtnBox {
    p {
        width:100px; 
        overflow:hidden;
        white-space: nowrap; /* 禁止文本换行 */
        text-overflow: ellipsis; /* 在文本末尾添加省略号 */
        display:inline-block; 
        margin:0;
    }
    span {
        float:right; 
        margin-right:10px;
    }
}
.popItem {
    padding: 5px 0;
    cursor: pointer;
    padding-left: 10px;
    &:hover {
        background: #409EFF;
        color: #fff;
    }
}
.cursor {
    cursor: pointer;
}
.isActive {
    background-color: #383B48;
    border-radius: 7px;
}
.right_content {
    padding-top: 0;
    padding-bottom: 0;
    min-height: calc(100% - 68px);
    min-width: 980px;
    .footerBox {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: calc(100%);
      flex-direction: column;
    }
    .searchValueBox {
        text-align: right;
        padding: 20px 30px
    }
    .fileInfo {
        margin: 0 30px;
        border-bottom: 1px solid #46485F;
        .files {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            .file {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 130px;
                height: 120px;
                margin-bottom: 20px;
                position: relative;
                .fileText {
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }
                .whiteBtn {
                    visibility: hidden;
                }
                &:hover {
                    background-color: #262833;
                    border-radius: 10px;
                    .whiteBtn {
                        visibility: visible;
                    }
                }
            }
            .active {
                background-color: #262833;
                border-radius: 10px;
            }
        }
    }
    .btnInfo {
        // overflow: hidden;
        padding: 0 30px;
        line-height: 40px;
        font-size: 14px;
        .rightBtn {
            float: right;
            .newBtnText {
                float: right;
                margin-left: 20px;
            }
            .checkBox {
                float: right;
                cursor: pointer;
            }
            .newImg {
                float: right;
                margin-left: 20px;
                cursor: pointer;
                .iconImg {
                    width: 22px;
                    height: 22px;
                    padding: 8px;
                }
            }
            .line1 {
                width: 1px;
                height: 40px;
                float: right;
                margin-left: 20px;
                background: #FEFBFB;
                cursor: pointer;
            }
            .newBtn {
                background: #383B48;
                border-radius: 7px;
                float: right;
                padding: 0px 15px;
                margin-left: 20px;
                font-size: 14px;
                cursor: pointer;
                .iconFont {
                    font-size: 20px;
                    position: relative;
                    top: 4px;
                }
            }
        }
        .leftBtn {
            float: left;
            position: relative;
            .text {
                float: left;
                margin-right: 10px;
            }
            .outBtn {
                float: left;
                margin-right: 30px;
                height: 30px;
                .span {
                    display: inline-block;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .iconSpan {
                    display: inline-block;
                    position: relative;
                    bottom: 13px;
                    margin-left: 3px;
                }
            }
        }
    }
    .rightInfo {
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        position: relative;
        padding: 20px 0 26px 26px;
        margin-right: 28px;
        .tableBox {
            width: 100%;
            
            .infoText {
                margin-top: 0;
                margin-left: 15px;
                margin-right: 15px;
                overflow: hidden;
                
            }
            .tubusi {
                position: absolute;
                top: 10px;
                padding: 4px 30px 4px 10px;
                border-top-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background: #4E526D;
                font-size: 12px;
                span {
                    font-size: 8px;
                    position: absolute;
                    top: 3;
                    margin-left: 5px;
                }
            }
            .tplType {
                position: absolute;
                top: 5px;
                right: 15px;
                width: 18px;
                height: 28px;
                padding: 10px 7px 10px 9px;
                background: url('@/assets/tpl.png') no-repeat;
                background-size: 100% 100%;
                border-top-right-radius: 5px;
                font-size: 8px;
                font-weight: 700;
                text-align: center;
                color: #da9d46;
            }
            .modelTitle {
                float: left;
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 700;
                opacity: .8;
                margin-bottom: 10px;
                color: #FEFCFC;
            }
            .modelTime {
                float: right;
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                font-weight: 700;
                opacity: .8;
                margin-bottom: 10px;
                color: #D2CFD0;
            }
            .empt {
                text-align: center;
                font-size: 20px;
                margin-top: 40px;
            }
            .pageBox {
                display: flex;
                margin:20px 0 ;

            }
            .contentBox {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                .fillSpan {
                    width: 210px;
                    height: 0;
                    background: #2B2E42;
                    box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
                    margin-left: 20px;
                    position: relative;
                }
                .infoItem {
                    width: 210px;
                    height: 240px;
                    background: #2B2E42;
                    box-shadow: 0 0 20px 0 rgba(14,14,14,.06);
                    margin-top: 20px;
                    margin-left: 20px;
                    position: relative;
                    .infoCheck {
                        position: absolute;
                        top: 0;
                        right: 20px;
                    }
                    .infoImg {
                        margin: 10px 15px;
                        width: 180px;
                        height: 150px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .imgBox {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 7px;
                                object-fit: fill;
                            }
                        }
                        .designBtn {
                            z-index: 99;
                            display: none;
                        }
                    }
                    &:hover {
                        .imgBox {
                            background: rgba(137, 207, 240,.2);
                        }
                        .designBtn {
                            display: flex;
                        }
                    }
                }
            }
        }
        .addButton {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    .leftInfo {
        flex-shrink: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        width: 216px;
        box-sizing: border-box;
        position: relative;
        padding: 24px 0px;
        border-right: 1px solid rgb(237, 238, 240);
        height: 100%;
        .tit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          .bigTitle {
            font-size: 18px;
            color: #000;
            font-weight: 700;
          }
          .addTitle {
            width: 80px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            box-sizing: border-box;
            background: rgb(255, 255, 255);
            font-size: 12px;
            color: rgb(96, 101, 108);
            font-variant: tabular-nums;
            font-feature-settings: "tnum";
            overflow: hidden;
            li {
                margin-bottom: 6px;
                padding:10px 0px;
                padding-left: 35px;
                list-style: none;
                position: relative;
                display: flex;
                flex-direction: column;
                font-size: 16px;
                &:hover {
                    color: rgb(96, 101, 108);
                    background-color: rgba(146, 152, 159, 0.08);
                }
            }
            .active {
                color: rgb(26, 122, 248);
                background-color: rgba(26, 122, 248, 0.08);
                &:hover {
                    color: rgb(26, 122, 248);
                    background-color: rgba(26, 122, 248, 0.08);
                }
            }
        }
    }
}
.perInfo {
  width: 120px;
  box-sizing: border-box;
  .footer {
    box-sizing: border-box;
    .menuList {
        width: 100%;
        .line {
            height: 1px;
            width: 100%;
            background: #686870;
            margin: 10px 0;
        }
        .menuItem {
            padding: 5px 0px;
            background-color: transparent;
            box-sizing: border-box;
            position: relative;
            user-select: none;
            width: 100%;
            font-size: 14px;
            overflow: hidden;
            line-height: 22px;
            .menuItemTitle {
                float: left;
            }
            .menuItemIcon {
                float: left;
                position: relative;
                .top {
                    position: absolute;
                    top: 0;
                    // bottom: 5px;
                    // right: 5px;
                    left: 5px;
                    &:hover {
                        color: var(--red);
                    }
                }
                .bottom {
                    position: absolute;
                    top: 10px;
                    // top: 5px;
                    left: 5px;
                    &:hover {
                        color: var(--red);
                    }
                }
                .active {
                    color: var(--red);
                }
            }
            span {
                display: block;
                width: 100%;
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:hover {
                .menuItemTitle {
                    color: var(--red);
                }
                span {
                    color: var(--red);
                }
            }
        }
    }
  }
}
</style>
