/**
 * 专门处理store的数据
 */
import store from '@/store'
const UserPerm = {
    hasPerm(code) {
        let userPerm = store.state.user.userPerm
        if(userPerm?.isSuper == 1) {
            return true
        } else {
            let codes = userPerm?.permCodes || []
            return this.ArrayInclude(codes, code)
        }
    },
    // 数组中是否包含元素
    ArrayInclude(arr, code) {
        if (arr && code && arr.includes(code)) {
            return true
        }
        return false
    },
    
}

export default UserPerm